"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertMillisecsToTimeString = exports.getTotalMilliseconds = void 0;

const getSeconds = val => val / 1000;

const getTotalMilliseconds = (division, val, value) => {
  let time = null;

  switch (division) {
    case 'second':
      time = getSeconds(parseInt(val, 10));
      break;

    case 'millisecond':
      time = parseInt(val, 10);
      break;
  }

  const duration = convertMillisecsToDuration(value);

  if (!duration) {
    return time;
  }

  const mergedTime = { ...duration,
    ...{
      [division]: val
    }
  };
  return getSeconds(parseInt(mergedTime.second, 10)) + parseInt(mergedTime.millisecond, 10);
};

exports.getTotalMilliseconds = getTotalMilliseconds;

const convertMillisecsToDuration = value => {
  if (value === null) {
    return null;
  }

  let ms = value;
  let s = Math.floor(ms / 1000);
  const second = `0${s || 0}`.slice(-2);
  const millisecond = `00${ms || 0}`.slice(-3);
  return {
    second,
    millisecond
  };
};

const convertMillisecsToTimeString = value => {
  const duration = convertMillisecsToDuration(value);

  if (!duration) {
    return null;
  }

  return `${duration.second}:${duration.millisecond}`;
};

exports.convertMillisecsToTimeString = convertMillisecsToTimeString;