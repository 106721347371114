"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toggle = void 0;

const react_1 = __importStar(require("react"));

const react_tippy_1 = require("react-tippy");

const foundations_1 = require("../../foundations");

const index_1 = require("./index");

exports.Toggle = react_1.default.memo(({
  onChange = () => {},
  checked,
  disabled,
  label,
  name,
  tooltip,
  value,
  small,
  ...others
}) => {
  const [isDown, setIsDown] = react_1.useState(false);

  const toggleChecked = e => {
    if (e) {
      e.preventDefault();
    }

    onChange(!checked);
  };

  const handleMouseDown = (isMouseDown = true) => {
    setIsDown(isMouseDown);
  };

  return react_1.default.createElement(index_1.StyledToggleContainer, Object.assign({
    htmlFor: name,
    tabIndex: disabled ? undefined : 0,
    onClick: disabled ? undefined : toggleChecked,
    onMouseDown: () => handleMouseDown(),
    onMouseUp: () => handleMouseDown(false),
    onMouseOut: () => handleMouseDown(false),
    onBlur: () => handleMouseDown(false),
    onKeyUp: e => {
      e.persist();

      if ((e.keyCode === 13 || e.keyCode === 32) && !disabled) {
        setIsDown(false);
        toggleChecked();
      }
    },
    onKeyDown: e => {
      if ((e.keyCode === 13 || e.keyCode === 32) && !disabled) {
        setIsDown(false);
      }
    }
  }, others), react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center"
  }, react_1.default.createElement(index_1.StyledToggle, {
    isDown: isDown,
    checked: checked,
    disabled: disabled,
    tabIndex: disabled ? undefined : -1,
    small: small
  }), label && react_1.default.createElement(react_tippy_1.Tooltip, {
    title: tooltip,
    position: "top",
    arrow: true,
    disabled: !tooltip
  }, react_1.default.createElement(index_1.StyledToggleLabel, {
    disabled: disabled,
    tabIndex: disabled ? undefined : -1,
    small: small
  }, label))), react_1.default.createElement("input", {
    checked: checked,
    disabled: disabled,
    name: name,
    type: "checkbox",
    value: value,
    onChange: e => onChange(e.currentTarget.checked)
  }));
});