"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigValue = exports.setConfig = void 0;
const object_1 = require("./object");
let _configStore = {};
const setConfig = (settings = {}) => {
    // Set default cluster domain
    let CLUSTER_DOMAIN = (settings === null || settings === void 0 ? void 0 : settings.clusterDomain) || 'cloud.oqton.ai';
    if (process.env.REACT_APP_CLUSTER_DOMAIN) {
        // Checks for command line variable passed in
        CLUSTER_DOMAIN = process.env.REACT_APP_CLUSTER_DOMAIN;
        // @ts-expect-error
    }
    else if (global.OQTON_CLUSTER_DOMAIN && !global.OQTON_CLUSTER_DOMAIN.startsWith('%')) {
        // check if we have a CLUSTER_DOMAIN defined in our index.html (written by nginx)
        // @ts-expect-error
        CLUSTER_DOMAIN = global.OQTON_CLUSTER_DOMAIN;
    }
    const API_ENDPOINT = process.env.REACT_APP_DEBUG_API_ENDPOINT || `https://api.${CLUSTER_DOMAIN}`;
    const ITEM_WEBSOCKET_ENDPOINT = process.env.REACT_APP_DEBUG_ITEM_WEBSOCKET_ENDPOINT || `wss://socket.${CLUSTER_DOMAIN}`;
    const IOT_WEBSOCKET_ENDPOINT = process.env.REACT_APP_DEBUG_IOT_WEBSOCKET_ENDPOINT || `wss://websockets.${CLUSTER_DOMAIN}`;
    const TENANT_API_ENDPOINT = process.env.REACT_APP_DEBUG_TENANT_API_ENDPOINT || `${API_ENDPOINT}/v1/tenants`;
    const TOOLS_API_ENDPOINT = process.env.REACT_APP_DEBUG_TOOLS_API_ENDPOINT || `${API_ENDPOINT}/v1/tools`;
    const FILES_API_ENDPOINT = process.env.REACT_APP_DEBUG_FILES_API_ENDPOINT || `${API_ENDPOINT}/v1/files`;
    const ALIYUN_API_ENDPOINT = process.env.REACT_APP_DEBUG_ALIYUN_API_ENDPOINT || `${API_ENDPOINT}/v1/aliyun`;
    const EXECUTIONER_API_ENDPOINT = process.env.REACT_APP_DEBUG_EXECUTIONER_API_ENDPOINT || `${API_ENDPOINT}/exec`;
    const urls = {
        CLUSTER_DOMAIN,
        API_ENDPOINT,
        ITEM_WEBSOCKET_ENDPOINT,
        IOT_WEBSOCKET_ENDPOINT,
        TENANT_API_ENDPOINT,
        TOOLS_API_ENDPOINT,
        FILES_API_ENDPOINT,
        ALIYUN_API_ENDPOINT,
        EXECUTIONER_API_ENDPOINT
    };
    const integrations = {
        ONSHAPE_API_ENDPOINT: process.env.DEBUG_ONSHAPE_API_ENDPOINT || `https://onshape-connector.${CLUSTER_DOMAIN}`,
        ONSHAPE_CLIENT_ID: process.env.DEBUG_ONSHAPE_CLIENT_ID || 'VWFDCMCASJAWZEY7KXX6DNJYYEUHOCMRC6WE7ZQ='
    };
    // Extrapolate & set defaults
    const { appName = 'OQTON', itemSchemaVersion = 'SCHEMA_VERSION_CALENDAR_EVENT', socketSchemaVersion = 'SOCKET_VERSION_INITIAL', toolVersions = {}, ...rest } = settings;
    // Store
    _configStore = {
        appName,
        urls,
        clusterDomain: CLUSTER_DOMAIN,
        itemSchemaVersion,
        socketSchemaVersion,
        toolVersions,
        integrations,
        ...rest
    };
};
exports.setConfig = setConfig;
const getConfigValue = (key) => {
    const v = object_1.getIn(_configStore, key);
    if (!v) {
        console.error("🌋 Trying to get a Config Value that doesn't exist: ", key);
    }
    return v;
};
exports.getConfigValue = getConfigValue;
