"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertSecsToTimeStringDays = exports.convertSecsToTimeString = exports.convertSecsToDuration = exports.getTotalSecondsDays = exports.getTotalSeconds = void 0;

const getDays = val => val * 60 * 60 * 24;

const getHours = val => val * 60 * 60;

const getMinutes = val => val * 60;

const getTotalSeconds = (division, val, value) => {
  let time = null;

  switch (division) {
    case 'hour':
      time = getHours(parseInt(val, 10));
      break;

    case 'minute':
      time = getMinutes(parseInt(val, 10));
      break;

    case 'second':
      time = parseInt(val, 10);
      break;
  }

  const duration = exports.convertSecsToDuration(value);

  if (!duration) {
    return time;
  }

  const mergedTime = { ...duration,
    ...{
      [division]: val
    }
  };
  return getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};

exports.getTotalSeconds = getTotalSeconds;

const getTotalSecondsDays = (division, val, value) => {
  let time = null;

  switch (division) {
    case 'day':
      time = getDays(parseInt(val, 10));
      break;

    case 'hour':
      time = getHours(parseInt(val, 10));
      break;

    case 'minute':
      time = getMinutes(parseInt(val, 10));
      break;

    case 'second':
      time = parseInt(val, 10);
      break;
  }

  const duration = convertSecsToDurationDays(value);

  if (!duration) {
    return time;
  }

  const mergedTime = { ...duration,
    ...{
      [division]: val
    }
  };
  return getDays(parseInt(mergedTime.day, 10)) + getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};

exports.getTotalSecondsDays = getTotalSecondsDays;

const convertSecsToDuration = value => {
  if (value === null) {
    return null;
  }

  let secondsInAMinute = 60;
  let secondsInAnHour = 60 * secondsInAMinute;
  let secondsInADay = 24 * secondsInAnHour; // extract days

  const d = Math.floor(value / secondsInADay); // extract hours

  let hourSeconds = value % secondsInADay;
  let h = Math.floor(hourSeconds / secondsInAnHour); // extract minutes

  let minuteSeconds = hourSeconds % secondsInAnHour;
  const m = Math.floor(minuteSeconds / secondsInAMinute); // extract the remaining seconds

  let remainingSeconds = minuteSeconds % secondsInAMinute;
  const s = Math.ceil(remainingSeconds);
  h += d * 24;
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    hour,
    minute,
    second
  };
};

exports.convertSecsToDuration = convertSecsToDuration;

const convertSecsToDurationDays = value => {
  if (value === null) {
    return null;
  }

  let secondsInAMinute = 60;
  let secondsInAnHour = 60 * secondsInAMinute;
  let secondsInADay = 24 * secondsInAnHour; // extract days

  const d = Math.floor(value / secondsInADay); // extract hours

  let hourSeconds = value % secondsInADay;
  const h = Math.floor(hourSeconds / secondsInAnHour); // extract minutes

  let minuteSeconds = hourSeconds % secondsInAnHour;
  const m = Math.floor(minuteSeconds / secondsInAMinute); // extract the remaining seconds

  let remainingSeconds = minuteSeconds % secondsInAMinute;
  const s = Math.ceil(remainingSeconds);
  const day = d.toString().padStart(2, '0');
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    day,
    hour,
    minute,
    second
  };
};

const convertSecsToTimeString = value => {
  const duration = exports.convertSecsToDuration(value);

  if (!duration) {
    return null;
  }

  return `${duration.hour}:${duration.minute}:${duration.second}`;
};

exports.convertSecsToTimeString = convertSecsToTimeString;

const convertSecsToTimeStringDays = value => {
  const duration = convertSecsToDurationDays(value);

  if (!duration) {
    return null;
  }

  return `${duration.day}:${duration.hour}:${duration.minute}:${duration.second}`;
};

exports.convertSecsToTimeStringDays = convertSecsToTimeStringDays;