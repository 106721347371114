"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InspectorPanel = exports.HeadingComponent = void 0;

const react_1 = __importStar(require("react"));

const styled_components_1 = __importStar(require("styled-components"));

const lodash_1 = require("lodash");

const ScrollColumn_1 = require("../ScrollColumn");

const foundations_1 = require("../../foundations");

const DropdownHeading_1 = require("../DropdownHeading");

const Heading_1 = require("../Heading");

const InspectorPanelStyles_1 = require("./InspectorPanelStyles");

const transformUrl_1 = require("./utils/transformUrl");

const accordionLineHeight = 56;
const InspectorPanelItemHeader = styled_components_1.default.div`
  ${({
  theme,
  showCursor
}) => `
    background-color: ${theme.colors.backgroundTertiary};
    border-bottom: 1px solid ${theme.colors.borderSecondary};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: ${showCursor ? 'pointer' : 'default'};
    height: ${accordionLineHeight}px;
    align-items: center;
    padding-right: ${theme.spacing.xl}px;
  `}
`;
InspectorPanelItemHeader.displayName = "InspectorPanelItemHeader";
const IconContainer = styled_components_1.default.div`
  ${({
  theme
}) => `
    align-self: center;
    padding-left: ${theme.spacing.xs}px;
    height: ${theme.spacing.l}px;
  `}
`;
IconContainer.displayName = "IconContainer";
const InspectorPanelItemContentWrapper = styled_components_1.default.div`
  ${({
  active,
  theme
}) => `
    height: calc(100% - ${accordionLineHeight}px);
    display: ${active ? 'block' : 'none'};
    padding-top: 12px;
    margin-left: -${theme.spacing.l}px;
  `}
`;
InspectorPanelItemContentWrapper.displayName = "InspectorPanelItemContentWrapper";
const ScrollContentWrapper = styled_components_1.default.div`
  ${({
  theme
}) => `
    padding-left: ${theme.spacing.l}px;
  `}
`;
ScrollContentWrapper.displayName = "ScrollContentWrapper";
exports.HeadingComponent = styled_components_1.default(props => react_1.default.createElement(Heading_1.Heading, Object.assign({}, props, {
  level: 2,
  size: "m"
})))`
  line-height: ${accordionLineHeight}px;
  user-select: none;
  width: auto;
`;
exports.HeadingComponent.displayName = "HeadingComponent";

const createButton = (text, disabled, icon, onClick, active) => ({
  disabled,
  rightIcon: icon,
  text,
  onClick,
  active
});

const handleOnClick = (defaultAction, key) => {
  defaultAction();
  transformUrl_1.transformUrl(key);
};

function InspectorPanel({
  steps = [],
  selectedStep,
  stepComponents,
  forceRender = false
}) {
  var _a, _b, _c;

  let selectedPanel = steps[0];
  const containerRef = react_1.useRef();
  const {
    spacing
  } = styled_components_1.useTheme();

  if (!!selectedStep) {
    selectedPanel = steps.find(step => step.key === selectedStep) || steps[0];
  }

  const [menuIsOpen, setMenuIsOpen] = react_1.useState(false);
  const menuItems = steps.map(step => createButton(step.title, false, step.iconComponent, () => handleOnClick(step.onClick, step.key), step.key === selectedPanel.key));
  const panelItems = lodash_1.map(stepComponents, (component, step) => react_1.default.createElement(InspectorPanelItemContentWrapper, {
    key: step,
    active: step === selectedStep
  }, react_1.default.createElement(ScrollColumn_1.ScrollColumn, null, react_1.default.createElement(ScrollContentWrapper, null, component))));
  const length = menuItems.length;
  const currentStepIndex = steps.findIndex(step => step.key === selectedStep);
  const count = `${currentStepIndex + 1}/${length}`;
  react_1.useEffect(() => {
    var _a;

    if ((_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth) {
      setMenuIsOpen(true);
    }
  }, [(_a = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _a === void 0 ? void 0 : _a.clientWidth]);
  return react_1.default.createElement(InspectorPanelStyles_1.Container, {
    ref: containerRef
  }, react_1.default.createElement(InspectorPanelItemHeader, {
    showCursor: menuItems.length > 1,
    onClick: () => setMenuIsOpen(!menuIsOpen)
  }, react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center"
  }, selectedPanel && react_1.default.createElement(react_1.default.Fragment, null, menuItems.length > 1 ? react_1.default.createElement(DropdownHeading_1.DropdownHeading, {
    icon: "CaretDown",
    align: "left",
    count: count,
    text: selectedPanel.title,
    onClose: () => setMenuIsOpen(false),
    isOpen: menuIsOpen,
    menuWidth: ((_b = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _b === void 0 ? void 0 : _b.clientWidth) ? ((_c = containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) === null || _c === void 0 ? void 0 : _c.clientWidth) - spacing.m : 0,
    menuItems: [{
      items: menuItems
    }]
  }) : react_1.default.createElement(exports.HeadingComponent, null, selectedPanel.title))), selectedPanel && selectedPanel.iconComponent && react_1.default.createElement(IconContainer, null, selectedPanel.iconComponent)), !forceRender && panelItems.find(item => item.key === selectedStep), forceRender && panelItems);
}

exports.InspectorPanel = InspectorPanel;