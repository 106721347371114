"use strict";
/**
 * A collection helpers for working with promises
 * @namespace promise
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.allSettledSuccessful = exports.MaxConcurrentPromiseRunner = exports.createWatchdogPromise = exports.createRetryPromise = exports.createTimeoutPromise = exports.delay = void 0;
/**
 * Create a promise that resolves after a specified amount of time.
 * Calling the cancel function on the promise will cause it to be rejected.
 * @memberof promise
 * @param {Number} ms requested delay in milliseconds
 * @param {AbortSignal} abortSignal abortSignal from abortController can be used to terminate the delay
 * @async
 * @return {Promise}
 */
function delay(ms, abortSignal) {
    let cancel = null;
    const cleanup = () => {
        if (abortSignal)
            abortSignal.removeEventListener('abort', cancel);
        cancel = null;
    };
    const promise = new Promise((res, rej) => {
        const timeout = setTimeout(res, ms);
        cancel = () => {
            clearTimeout(timeout);
            rej(new Error('Canceled'));
        };
    });
    // add event listener
    if (abortSignal)
        abortSignal.addEventListener('abort', cancel, { once: true });
    // remove event listener when done
    promise.then(cleanup, cleanup);
    return promise;
}
exports.delay = delay;
/**
 * Creates a promise that rejects after a specified amount of time.
 * @memberof promise
 * @param {Number} timeout requested delay in milliseconds
 * @param {String} msg message of the timeout error
 * @param {Class} [ErrorClass=Error] optional type of error to throw
 * @async
 * @return {Promise}
 */
async function createTimeoutPromise(timeout, msg = 'Promise did not resolve in time', ErrorClass = Error, abortSignal) {
    await delay(timeout, abortSignal);
    await delay(1, abortSignal);
    throw new ErrorClass(`Timeout (${timeout / 1000}s): ${msg}`);
}
exports.createTimeoutPromise = createTimeoutPromise;
/**
 * Creates a promise that will attempt to execute an async function multiple times until succeeded.
 * @memberof promise
 * @param {Number} attempts how many times to try before giving up
 * @param {Number} [delayMs=0] how long to wait between retries
 * @param {async function} promiseGenerator the async function to execute; every time it throws an error it will be restarted
 * @async
 * @return {Promise}
 */
async function createRetryPromise(attempts, delayMs = 0, promiseGenerator) {
    for (let i = 0; i < attempts; i += 1) {
        try {
            return await promiseGenerator();
        }
        catch (e) {
            await delay(delayMs);
            if (i === attempts - 1)
                throw e;
        }
    }
}
exports.createRetryPromise = createRetryPromise;
/**
 * Creates a watchdog: a promise that will reject after a specified amount of time of not being "pacified".
 * Used to check that a specific action is performed frequently enough.
 * E.g. if no response is received every minute, we assume the network connection is dead and throw an error.
 * The watchdog has the following methods:
 * pacify(): call this regularly to prevent the error
 * start(): call this to start the watchdog
 * stop(): call this to stop the watchdog; it won't throw an error even if you don't pacify it
 * catch(): use as a promise
 * @memberof promise
 * @param {Number} attempts how many times to try before giving up
 * @param {Number} [delayMs=0] how long to wait between retries
 * @param {async_function} promiseGenerator the async function to execute; every time it throws an error it will be restarted
 * @async
 * @return {Promise}
 */
function createWatchdogPromise(timeout, msg = 'Watchdog was not pacified in time') {
    let timeoutFn;
    // @ts-expect-error
    const promise = new Promise((res, rej) => {
        timeoutFn = () => {
            rej(new Error(`Watchdog (${timeout / 1000}s): ${msg}`));
        };
    });
    let timer = null;
    promise.pacify = function _pacify() {
        // let the watchdog know that all is well
        clearTimeout(timer);
        timer = setTimeout(timeoutFn, timeout);
        return this;
    };
    promise.start = function _start() {
        if (!timer) {
            timer = setTimeout(timeoutFn, timeout);
        }
        return this;
    };
    promise.stop = function _stop() {
        clearTimeout(timer);
        timer = null;
        return this;
    };
    promise.catch = function _catch(reason) {
        // @ts-expect-error
        const p = Promise.prototype.catch.call(this, reason);
        p.pacify = promise.pacify;
        p.start = promise.start;
        p.stop = promise.stop;
        return p;
    };
    return promise;
}
exports.createWatchdogPromise = createWatchdogPromise;
class MaxConcurrentPromiseRunner {
    constructor(maxConcurrentPromises) {
        this.maxConcurrentPromises = maxConcurrentPromises;
        this.queue = [];
        this.running = 0;
    }
    add(fn) {
        return new Promise((resolve, reject) => {
            this.queue.push({ resolve, reject, fn });
            this.run();
        });
    }
    run() {
        if (this.running < this.maxConcurrentPromises && this.queue.length > 0) {
            this.running += 1;
            const job = this.queue.pop();
            job
                .fn()
                .then((value) => {
                this.running -= 1;
                job.resolve(value);
                this.run();
            })
                .catch((error) => {
                this.running -= 1;
                job.reject(error);
                this.run();
            });
        }
    }
}
exports.MaxConcurrentPromiseRunner = MaxConcurrentPromiseRunner;
async function allSettledSuccessful(promises) {
    let firstError = null;
    return Promise.all(promises.map(p => p.catch(e => {
        firstError = e;
        return null;
    }))).then(r => {
        if (firstError)
            throw firstError;
        return r;
    });
}
exports.allSettledSuccessful = allSettledSuccessful;
