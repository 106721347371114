"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clamp = exports.capitalize = void 0;

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.substr(1);
}

exports.capitalize = capitalize;

function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

exports.clamp = clamp;