"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoadingQA = void 0;

const testutils_1 = require("../../testutils");

const Icon_1 = require("../Icon");

class LoadingQA extends testutils_1.QA {
  get icon() {
    return this.get(Icon_1.IconQA);
  }

}

exports.LoadingQA = LoadingQA;
LoadingQA.componentName = 'Loading';
LoadingQA.selector = "[data-testid='loading']";