import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components/App/App';

import { routes, NotFound } from './routes';

ReactDOM.render(
  <React.StrictMode>
    <App routes={routes} NotFound={NotFound} />
  </React.StrictMode>,
  document.getElementById('root')
);
