"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.smallText = exports.tinyText = exports.text = exports.inputValue = exports.tableHeadersSorted = exports.tableHeaders = exports.tableDefault = exports.tableAccent = exports.metaData = exports.headingXXS = exports.headingXS = exports.headingS = exports.headingM = exports.headingL = exports.headingXL = exports.inputLabel = exports.inputError = exports.editorNavigatorL2 = void 0;

const utils_core_1 = require("@oqton/utils-core");

const getTextFontWeight = (mode, theme) => {
  switch (mode) {
    case 'primary':
      return theme.typography.fontWeights.bold;

    case 'secondary':
      return theme.typography.fontWeights.medium;

    case 'tertiary':
      return theme.typography.fontWeights.normal;

    default:
      return theme.typography.fontWeights.medium;
  }
};

const editorNavigatorL2 = ({
  colors,
  typography,
  spacing
}) => `
    color: ${colors.primary};
    font-size: ${typography.fontSizes.xs}px;
    font-weight: ${typography.fontWeights.thin};
    letter-spacing: ${typography.letterSpacing.s}em;
    line-height: ${spacing.m}px;
  `;

exports.editorNavigatorL2 = editorNavigatorL2;

const inputError = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.accentRed};
  font-size: ${typography.fontSizes.xxs}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.s}px;
`;

exports.inputError = inputError;

const inputLabel = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xxs}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: ${typography.letterSpacing.m}em;
  line-height: ${spacing.s}px;
`;

exports.inputLabel = inputLabel;

const headingXL = ({
  colors,
  typography
}) => `
    color: ${colors.primary};
    font-size: ${typography.fontSizes.xl}px;
    font-weight: ${typography.fontWeights.bold};
    letter-spacing: ${typography.letterSpacing.s}em;
    line-height: 36px;
  `;

exports.headingXL = headingXL;

const headingL = ({
  colors,
  typography,
  spacing
}) => `
    color: ${colors.primary};
    font-size: 20px;
    font-weight: ${typography.fontWeights.bold};
    letter-spacing: ${typography.letterSpacing.m}em;
    line-height: ${spacing.xl}px;
  `;

exports.headingL = headingL;

const headingM = ({
  colors,
  typography,
  spacing
}) => `
    color: ${colors.primary};
    font-size: 17px;
    font-weight: ${typography.fontWeights.bold};
    letter-spacing: ${typography.letterSpacing.m}em;
    line-height: ${spacing.l}px;
  `;

exports.headingM = headingM;

const headingS = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: 15px;
  font-weight: ${typography.fontWeights.bold};
  letter-spacing: ${typography.letterSpacing.m}em;
  line-height: ${spacing.l}px;
`;

exports.headingS = headingS;

const headingXS = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: 15px;
  font-weight: ${typography.fontWeights.normal};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.l}px;
`;

exports.headingXS = headingXS;

const headingXXS = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: ${typography.fontSizes.m}px;
  font-weight: ${typography.fontWeights.bold};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.l}px;
`;

exports.headingXXS = headingXXS;

const metaData = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xxs}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: ${typography.letterSpacing.m}em;
  line-height: ${spacing.s}px;
`;

exports.metaData = metaData;

const tableAccent = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: ${typography.fontSizes.m}px;
  font-weight: ${typography.fontWeights.bold};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.xl}px;
  font-variant-numeric: tabular-nums;
`;

exports.tableAccent = tableAccent;

const tableDefault = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: ${typography.fontSizes.m}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.xl}px;
  font-variant-numeric: tabular-nums;
`;

exports.tableDefault = tableDefault;

const tableHeaders = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xs}px;
  font-weight: ${typography.fontWeights.thin};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.xl}px;
`;

exports.tableHeaders = tableHeaders;

const tableHeadersSorted = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.secondary};
  font-size: ${typography.fontSizes.xs}px;
  font-weight: ${typography.fontWeights.medium};
  letter-spacing: ${typography.letterSpacing.s}em;
  line-height: ${spacing.xl}px;
`;

exports.tableHeadersSorted = tableHeadersSorted; // This is still slightly different

const inputValue = ({
  colors,
  typography,
  spacing
}) => `
  color: ${colors.primary};
  font-size: ${typography.inputValue}px;
  font-weight: ${typography.fontWeights.medium};
  letter-spacing: ${typography.letterSpacing.m}em;
  line-height: ${spacing.m}px;
  font-variant-numeric: tabular-nums;
`;

exports.inputValue = inputValue;

const text = ({
  theme,
  mode = 'secondary',
  color
}) => `
  color: ${color ? utils_core_1.getIn(theme.colors, color) : theme.colors.primary};
  font-size: ${theme.typography.fontSizes.m}px;
  font-weight: ${getTextFontWeight(mode, theme)};
  letter-spacing: ${theme.typography.letterSpacing.s};
  line-height: ${theme.spacing.m}px;
  font-variant-numeric: tabular-nums;
`;

exports.text = text;

const tinyText = ({
  theme,
  color
}) => `
  color: ${color ? utils_core_1.getIn(theme.colors, color) : theme.colors.primary};
  font-size: ${theme.typography.fontSizes.xxs}px;
  font-weight: ${theme.typography.fontWeights.medium};
  letter-spacing: ${theme.typography.letterSpacing.s}em;
  line-height: ${theme.spacing.s}px;
  font-variant-numeric: tabular-nums;
`;

exports.tinyText = tinyText;

const getSmallTextFontWeight = (mode, theme) => {
  switch (mode) {
    case 'primary':
      return theme.typography.fontWeights.medium;

    case 'secondary':
      return theme.typography.fontWeights.normal;

    default:
      return theme.typography.fontWeights.normal;
  }
};

const smallText = ({
  theme,
  mode = 'secondary',
  color
}) => `
  color: ${color ? utils_core_1.getIn(theme.colors, color) : theme.colors.primary};
  font-size: ${theme.typography.fontSizes.s}px;
  font-weight: ${getSmallTextFontWeight(mode, theme)};
  letter-spacing: ${theme.typography.letterSpacing.s}em;
  line-height: 13px;
  font-variant-numeric: tabular-nums;
`;

exports.smallText = smallText; // Table styles
// export const tableAccent = (theme: CurrentTheme) => `
//   ${text({ theme, mode: 'primary' })}
// `;
// export const tableDefault = (theme: CurrentTheme) => `
//   ${text({ theme, mode: 'tertiary' })}
// `;
// export const tableHeaders = (theme: CurrentTheme) => `
//   ${smallText({ theme, color: 'secondary' })}
// `;
// export const tableHeadersSorted = (theme: CurrentTheme) => `
//   ${smallText({ theme, mode: 'primary', color: 'secondary' })}
// `;