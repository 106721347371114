"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalNavContextProvider = exports.GlobalNavContext = void 0;

const react_1 = __importStar(require("react")); // Set the default value and setter function for the context


exports.GlobalNavContext = react_1.createContext({}); // As described in the GlobalNavBlock, this Provider must be a parent of the GlobakNavBlock
// in order for the GlobalNavBlock to work
// This component helps from writing duplicate menu open/close handlers for each layout

const GlobalNavContextProvider = ({
  children
}) => {
  // Default to menu being toggled closed (false)
  // Until a user clicks on the menu button, the breakpoint will determine whether to render the GlobalNav
  const [open, setOpen] = react_1.useState(false); // set the scrolltop for menu to 0 and track in state
  // so we can persist the scroll top on reload of globalnav

  const [scrollTop, setScrollTop] = react_1.useState(0);
  const globalNavRef = react_1.useRef(null);
  react_1.useEffect(() => {
    const {
      current
    } = globalNavRef;

    function handleDocumentClick() {
      // We nearly avoided a messy race condition. When the user clicks on the "Menu" button it fires two events
      // 1. this component's window event listener
      // 2. the menu button's click event
      // We can make sure to only set the menuIsToggled to "closed" if it was open, which works with the event order
      if (current && open) {
        setOpen(false);
      }
    }

    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick, false);
    };
  }, [open]);
  const value = react_1.useMemo(() => ({
    scrollTop,
    open,
    setOpen,
    globalNavRef,
    setScrollTop
  }), [scrollTop, open]);
  return react_1.default.createElement(exports.GlobalNavContext.Provider, {
    value: value
  }, children);
};

exports.GlobalNavContextProvider = GlobalNavContextProvider;