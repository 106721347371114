import { useState } from 'react';
import { reduce, get } from 'lodash';
import ReactGA from 'react-ga';

import { useStripe } from '@stripe/react-stripe-js';

import { config } from '../../config/config';

const getPlan = path => get(path, process.env.NODE_ENV === 'development' ? 'test' : 'prod');

export const useCheckout = (data, type) => {
  const stripe = useStripe();

  const [error, setError] = useState();
  const [busy, setBusy] = useState(false);

  const anyIot =
    get(data.monthly.iotSmall, 'quantity') > 0 ||
    get(data.monthly.iotMedium, 'quantity') > 0 ||
    get(data.monthly.iotLarge, 'quantity') > 0 ||
    false;

  const monthlyTotal = reduce(data.monthly, (a, v) => (a += v.amount * v.quantity), 0);

  const handleCheckoutWithStripe = async () => {
    // Disable our button
    setBusy(true);

    try {
      // Construct our objects to send to the server
      const itemsMonthly = reduce(
        data.monthly,
        (a, v) => {
          v.quantity > 0 && a.push({ plan: getPlan(v.plan), quantity: v.quantity });
          return a;
        },
        []
      );

      const body = {
        type,
        subscriptionData: {
          items: itemsMonthly,
          trial_from_plan: true
        }
      };

      // Custom logic for one-offs goes here
      if (anyIot) {
        body.lineItems = [{ ...data.setup.edgeGatewaySetup, amount: data.setup.edgeGatewaySetup.amount * 100 }]; // amount for line items is x 100
        body.subscriptionData.items.push({ plan: getPlan(data.monthly.edgeGatewayMonthly.plan), quantity: 1 });
      }

      if (type === 'mes') {
        body.lineItems = [{ ...data.setup.nfcSetup, amount: data.setup.nfcSetup.amount * 100 }]; // amount for line items is x 100
      }

      // First get our remote session
      const postData = await fetch(`${config.api.host}/session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      const { sessionId } = await postData.json();

      // track redirect
      ReactGA.pageview('/go-to-checkout');

      // Redirect to stripe
      stripe.redirectToCheckout({ sessionId }).then(function(result) {
        if (result.error) {
          setError(result.error.message);
        }
      });
    } catch (e) {
      console.log('ERROR', e);
      setBusy(false);
      setError('Error redirecting to payment gateway, please try again');
    }
  };

  return {
    error,
    busy,
    anyIot,
    monthlyTotal,
    handleCheckoutWithStripe
  };
};
