"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAsString = exports.formatAsTokens = void 0;
const getUnitDefinition_1 = require("./getUnitDefinition");
/**
 * Format input as an array of tokens.
 * @param unitDefinitionOrName The name of the unit, or the definition itself.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param withSymbol Whether to include the symbol in the formatted output.
 */
function formatAsTokens(unitDefinitionOrName, input, precision, withSymbol = true) {
    const unit = typeof unitDefinitionOrName === 'string'
        ? getUnitDefinition_1.getUnitDefinition(unitDefinitionOrName)
        : unitDefinitionOrName;
    if (unit == null) {
        throw new Error(`No such unit definition: ${String(unitDefinitionOrName)}`);
    }
    return unit.formatAsTokens(input, precision, withSymbol);
}
exports.formatAsTokens = formatAsTokens;
/**
 * Format input as a string.
 * @param unitDefinitionOrName The name of the unit, or the definition itself.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param withSymbol Whether to include the symbol in the formatted output.
 */
function formatAsString(unitDefinitionOrName, input, precision, withSymbol = true) {
    const unit = typeof unitDefinitionOrName === 'string'
        ? getUnitDefinition_1.getUnitDefinition(unitDefinitionOrName)
        : unitDefinitionOrName;
    if (unit == null) {
        throw new Error(`No such unit definition: ${String(unitDefinitionOrName)}`);
    }
    return unit.formatAsString(input, precision, withSymbol);
}
exports.formatAsString = formatAsString;
