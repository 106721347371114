"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFunction = void 0;

const react_1 = require("react");
/**
 * Similar to `useCallback` but returns a function which will always have the
 * same identity even if the dependencies or function itself changes.
 * @param {Function} fn The function which should be memoized.
 * @param {Array<mixed>} dependencies The dependencies which the function relies on.
 * @returns {Function} a constant reference to the function.
 */


function useFunction(fn, dependencies) {
  const ref = react_1.useRef();
  ref.current = fn;
  /* eslint-disable react-hooks/exhaustive-deps */

  return react_1.useCallback((...args) => ref.current.apply(undefined, args), dependencies);
  /* eslint-enable react-hooks/exhaustive-deps */
}

exports.useFunction = useFunction;