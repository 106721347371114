"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VisibilityControls = void 0;

const react_1 = __importStar(require("react"));

const Icon_1 = require("../Icon");

const VisibilityControls = props => {
  const {
    isHidden,
    isLocked,
    onClickHidden,
    onClickLock,
    disabled
  } = props;

  const _onClickHidden = react_1.useCallback(e => {
    if (!disabled) {
      e.stopPropagation();

      if (onClickHidden) {
        onClickHidden(e);
      }
    }
  }, [disabled, onClickHidden]);

  const _onClickLock = react_1.useCallback(e => {
    if (!disabled) {
      e.stopPropagation();

      if (onClickLock) {
        onClickLock(e);
      }
    }
  }, [disabled, onClickLock]);

  if (disabled) {
    return react_1.default.createElement(react_1.default.Fragment, null);
  }

  return react_1.default.createElement("div", {
    "data-testid": "VisibilityControls",
    "data-disabled": disabled,
    style: {
      display: 'flex'
    }
  }, isLocked === true && react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    mr: "xs",
    name: "InputLocked",
    onMouseDown: _onClickLock,
    cursor: "pointer",
    "data-testid": "locked"
  }), isLocked === false && react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    mr: "xs",
    name: "InputUnlocked",
    onMouseDown: _onClickLock,
    cursor: "pointer",
    "data-testid": "unlocked"
  }), isHidden === true && react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    mr: "xs",
    name: "InputHidden",
    onMouseDown: _onClickHidden,
    cursor: "pointer",
    "data-testid": "hidden"
  }), isHidden === false && react_1.default.createElement(Icon_1.Icon, {
    disabled: disabled,
    mr: "xs",
    name: "InputVisible",
    onMouseDown: _onClickHidden,
    cursor: "pointer",
    "data-testid": "visible"
  }));
};

exports.VisibilityControls = VisibilityControls;