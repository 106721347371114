"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPointAsString = exports.formatPointAsTokens = void 0;
const FormatToken_1 = require("../FormatToken");
/**
 * Format a point as tokens.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol for the point.
 */
function formatPointAsTokens(input, precision, symbol) {
    if (input == null || !Array.isArray(input)) {
        return [];
    }
    const tokens = input.map(item => ({
        type: 'value',
        value: precision == null ? String(+item) : String(Number((+item).toFixed(precision)))
    }));
    if (symbol != null && symbol.length > 0) {
        tokens.push({
            type: 'symbol',
            symbol
        });
    }
    return tokens;
}
exports.formatPointAsTokens = formatPointAsTokens;
/**
 * Format a point as a string.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol for the point.
 */
function formatPointAsString(input, precision, symbol) {
    return FormatToken_1.joinFormatTokens(formatPointAsTokens(input, precision, symbol), ' ') || '-';
}
exports.formatPointAsString = formatPointAsString;
