"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimePicker = void 0;

const react_1 = __importStar(require("react"));

const foundations_1 = require("../../foundations");

const index_1 = require("../index");

const dateTimePickerHelpers_1 = require("../../utils/dateTimePickerHelpers");

const index_2 = require("./index");

const TimePicker = ({
  disabled = false,
  onFocus,
  hasDropdown = true,
  onChange,
  value,
  format = 'HH:MM:SS',
  mb = 'm',
  align = 'left',
  ...rest
}) => {
  const dropdownButtonRef = react_1.useRef();
  const pickerRef = react_1.useRef();
  const panelRef = react_1.useRef();
  const [open, setOpen] = react_1.useState(false);
  const handleClick = react_1.useCallback(e => {
    if (disabled) {
      return e.preventDefault();
    }

    const panelNode = panelRef.current;
    const dropdownButtonNode = dropdownButtonRef.current;
    const pickerNode = pickerRef.current; // Keep open if clicking again on the masked input whilst open

    if (e.target.type === 'text' && open) {
      e.preventDefault();
      return;
    }

    if (hasDropdown) {
      if (dropdownButtonNode && dropdownButtonNode.contains(e.target) || e.target === pickerNode) {
        setOpen(!open);
        e.preventDefault();
        return;
      } // Open (or keep open) the panel if you click on it


      if (panelNode && panelNode.contains(e.target)) {
        setOpen(true);
        e.preventDefault();
        return;
      } // If the click is anywhere else, close the panel


      setOpen(false);
    } // Toggle the panel open state if you click on the dropdown button

  }, [disabled, hasDropdown, open]);
  react_1.useEffect(() => {
    if (typeof window !== `undefined`) {
      document.addEventListener('mousedown', handleClick, false);
    }

    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('mousedown', handleClick, false);
      }
    };
  }, [handleClick]);

  const handleFocus = e => {
    if (disabled) {
      return e.preventDefault();
    }

    if (onFocus) onFocus(e);
  };

  const updateTime = (division, val) => {
    const timeString = dateTimePickerHelpers_1.updateTime(division, val, value || '', format);
    onChange(timeString);
  };

  return react_1.default.createElement(foundations_1.Box, {
    mb: mb
  }, react_1.default.createElement(index_2.StyledTimeInput, Object.assign({
    format: format,
    hasDropdown: true,
    dropdownIsOpen: open,
    pickerRef: pickerRef,
    dropdownButtonRef: dropdownButtonRef,
    value: value,
    onFocus: handleFocus,
    onChange: onChange,
    disabled: disabled
  }, rest)), open && react_1.default.createElement(index_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    // if (!(parentRect instanceof DOMRect)) return null; // FAILS ON TEST because DOMRect undefined
    if (!parentRect || parentRect.top === undefined) return null;
    return react_1.default.createElement(index_2.TimePickerStyles.PanelWrapper, {
      "data-testid": "panel-wrap",
      ref: panelRef,
      parentRect: parentRect
    }, react_1.default.createElement(index_2.TimePickerPanel, {
      align: align,
      onHourSelect: hour => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.hour, hour);
      },
      onMinuteSelect: minute => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.minute, minute);
      },
      onSecondSelect: second => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.second, second);
      },
      format: format,
      international: true,
      value: value
    }));
  }));
};

exports.TimePicker = TimePicker;