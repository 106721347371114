"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColorPicker = exports.rgbToHex = exports.getColorOptions = exports.supportedColors = void 0;

const react_1 = __importStar(require("react"));

const useTranslation_1 = require("../../hooks/useTranslation");

const Select_1 = require("../Select");

const hooks_1 = require("../../hooks");

exports.supportedColors = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'indigo', 'violet', 'pink', 'gray'];

const getColorOptions = (t, colors) => {
  return Object.keys(colors).map(color => {
    // @ts-expect-error
    const c = colors[color];
    return {
      label: t(`designSystem:components.colorPicker.${color}`),
      value: color,
      color: c[0],
      rgb: ~~parseInt('ff' + c[0].substring(1), 16)
    };
  });
};

exports.getColorOptions = getColorOptions;

function rgbToHex(value) {
  return `#${((value || 0 >>> 0) & 0xffffff).toString(16)}`; // cut off alpha channel as not supported by html color picker
}

exports.rgbToHex = rgbToHex;
/**
 * A simple color picker component
 * @example
 * <ColorPicker type="input" /> Will allow the user to pick any color using a browser input swatch
 * <ColorPicker type="select" /> Will allow the user to pick from a limited color list
 */

const ColorPicker = props => {
  const {
    onChange,
    value = 0,
    label,
    type = 'input',
    disabled = false
  } = props;
  const {
    t
  } = useTranslation_1.useTranslation();
  const {
    colors
  } = hooks_1.useTheme();
  const options = react_1.useMemo(() => {
    return exports.getColorOptions(t, colors.chartColors);
  }, [colors, t]);
  const handleInputChange = react_1.useCallback(e => {
    const hex = e.currentTarget.value; // FYI ~~y; is a 'trick'—similar to var x = y << 0;—that (ab)uses the unary bitwise NOT operator to force the result to be in the range of a signed 32-bit integer, discarding any non-integer portion.

    const rgb = ~~parseInt('ff' + hex.substring(1), 16); // add #ff as alpha channel and convert to int32

    onChange(rgb, hex);
  }, [onChange]);
  const handleSelectChange = react_1.useCallback(selected => {
    if (selected && selected.color) {
      // rgb, hex, name
      onChange(selected.rgb, selected.color, selected.value);
    } else {
      onChange(null, null);
    }
  }, [onChange]);

  if (type === 'input') {
    return react_1.default.createElement("input", {
      type: "color",
      value: rgbToHex(value),
      onChange: handleInputChange,
      disabled: disabled
    });
  }

  return react_1.default.createElement(Select_1.Select, {
    label: label || t('designSystem:components.colorPicker.color'),
    value: value,
    options: options,
    onChange: handleSelectChange,
    disabled: disabled
  });
};

exports.ColorPicker = ColorPicker;