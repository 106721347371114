"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generatorWithPeriodicUpdate = exports.returnValueFromAsyncGenerator = void 0;
const subject_1 = require("@async-generators/subject");
async function returnValueFromAsyncGenerator(generator) {
    while (true) {
        const { done, value } = await generator.next();
        if (done)
            return value;
    }
}
exports.returnValueFromAsyncGenerator = returnValueFromAsyncGenerator;
async function* generatorWithPeriodicUpdate(promise, callBack, intervalMs) {
    let returnValue = null;
    const subject = new subject_1.Subject();
    const interval = setInterval(() => {
        const r = callBack();
        subject.next(r);
    }, intervalMs);
    promise
        .then(r => {
        returnValue = r;
        subject.done();
    }, e => {
        subject.error(e);
    })
        .finally(() => {
        clearInterval(interval);
    });
    //@ts-expect-error ts bug in @async-generators/subject
    yield* subject;
    return returnValue;
}
exports.generatorWithPeriodicUpdate = generatorWithPeriodicUpdate;
