"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCurrencyAsString = exports.formatCurrencyAsTokens = void 0;
const FormatToken_1 = require("../FormatToken");
/**
 * Format a currency amount as tokens.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol to use.
 */
function formatCurrencyAsTokens(input, precision, symbol) {
    if (input == null || isNaN(+input)) {
        return [];
    }
    const value = precision == null ? String(+input) : String(Number((+input).toFixed(precision)));
    const tokens = [];
    if (symbol != null && symbol.length > 0) {
        tokens.push({
            type: 'symbol',
            symbol
        });
    }
    tokens.push({
        type: 'value',
        value
    });
    return tokens;
}
exports.formatCurrencyAsTokens = formatCurrencyAsTokens;
/**
 * Format a currency amount as string.
 * @param input The input to format.
 * @param precision The precision with which to format the input.
 * @param symbol The symbol to use.
 */
function formatCurrencyAsString(input, precision, symbol) {
    return FormatToken_1.joinFormatTokens(formatCurrencyAsTokens(input, precision, symbol)) || '-';
}
exports.formatCurrencyAsString = formatCurrencyAsString;
