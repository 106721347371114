"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.multiChartColors = exports.sortChartColors = exports.randomHsl = void 0;
/**
 * Random color generator
 * This function generates vibrant, "evenly spaced" colours (i.e. no clustering).
 * @param {number} numOfSteps
 * @param {number} step
 * @return {string}
 */

const randomHsl = (numOfSteps = 1, step = 1) => `hsla(${360 / numOfSteps * step}, ${~~(50 + 30 / numOfSteps * step)}%, 40%, 1)`;

exports.randomHsl = randomHsl;
/**
 * Sort Chart colors
 */

const sortChartColor = colorArray => {
  const sorted = [];
  let colorOrder = [3, 5, 1, 4, 2, 6, 0];
  colorOrder.forEach(i => {
    sorted.push(colorArray[i]);
  });
  return sorted;
};

const sortChartColors = chartColors => {
  const chartColorsKeys = Object.keys(chartColors);
  let colors = { ...chartColors
  };
  chartColorsKeys.forEach(color => colors[color] = sortChartColor(chartColors[color]));
  return colors;
};

exports.sortChartColors = sortChartColors;

const multiChartColors = chartColors => {
  const monochrome = ['blue', 'red', 'gray', 'yellow', 'violet', 'green', 'indigo', 'orange', 'pink', 'teal'];
  let colorOrder = [3, 5, 1, 4, 2, 6, 0];
  let colors = [];
  colorOrder.forEach(order => {
    monochrome.forEach(element => {
      colors.push(chartColors[element][order]);
    });
  });
  return colors;
};

exports.multiChartColors = multiChartColors;