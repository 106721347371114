"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Star = exports.Squircle = exports.RoundedRectangle = exports.Polygon = exports.Diamond = exports.Circle = void 0;
var Circle_1 = require("./Circle");
Object.defineProperty(exports, "Circle", { enumerable: true, get: function () { return Circle_1.Circle; } });
var Diamond_1 = require("./Diamond");
Object.defineProperty(exports, "Diamond", { enumerable: true, get: function () { return Diamond_1.Diamond; } });
var Polygon_1 = require("./Polygon");
Object.defineProperty(exports, "Polygon", { enumerable: true, get: function () { return Polygon_1.Polygon; } });
var RoundedRectangle_1 = require("./RoundedRectangle");
Object.defineProperty(exports, "RoundedRectangle", { enumerable: true, get: function () { return RoundedRectangle_1.RoundedRectangle; } });
var Squircle_1 = require("./Squircle");
Object.defineProperty(exports, "Squircle", { enumerable: true, get: function () { return Squircle_1.Squircle; } });
var Star_1 = require("./Star");
Object.defineProperty(exports, "Star", { enumerable: true, get: function () { return Star_1.Star; } });
