"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertSecsToDurationString = exports.convertSecsToTimeString = exports.convertSecsToDuration = exports.getTotalSeconds = exports.between = exports.max = exports.min = exports.isValidNumber = void 0;

const isValidNumber = a => typeof a === 'number' && !Number.isNaN(a);

exports.isValidNumber = isValidNumber;

const min = (...args) => Math.min(...args.filter(exports.isValidNumber));

exports.min = min;

const max = (...args) => Math.max(...args.filter(exports.isValidNumber));

exports.max = max;

const between = (value, minValue, maxValue) => {
  if (minValue && minValue > value) {
    return minValue;
  }

  if (maxValue && maxValue < value) {
    return maxValue;
  }

  return value;
};

exports.between = between;

const getHours = val => val * 60 * 60;

const getMinutes = val => val * 60;

const getTotalSeconds = (division, val, value) => {
  let time = null;

  if (division === 'hour') {
    time = getHours(parseInt(val, 10));
  } else if (division === 'minute') {
    time = getMinutes(parseInt(val, 10));
  } else if (division === 'second') {
    time = parseInt(val, 10);
  }

  const duration = exports.convertSecsToDuration(value);

  if (!duration) {
    return time;
  }

  const mergedTime = { ...duration,
    ...{
      [division]: val
    }
  };
  return getHours(parseInt(mergedTime.hour, 10)) + getMinutes(parseInt(mergedTime.minute, 10)) + parseInt(mergedTime.second, 10);
};

exports.getTotalSeconds = getTotalSeconds;

const convertSecsToDuration = value => {
  if (value === null) {
    return null;
  }

  let s = value;
  let m = Math.floor(s / 60);
  s = s % 60;
  let h = Math.floor(m / 60);
  let d = Math.floor(h / 24);
  m = m % 60;
  h = h % 24;
  h += d * 24;
  const hour = h.toString().padStart(2, '0');
  const minute = `0${m || 0}`.slice(-2);
  const second = `0${s || 0}`.slice(-2);
  return {
    hour,
    minute,
    second
  };
};

exports.convertSecsToDuration = convertSecsToDuration;

const convertSecsToTimeString = value => {
  const duration = exports.convertSecsToDuration(value);

  if (!duration) {
    return null;
  }

  return `${duration.hour}:${duration.minute}:${duration.second}`;
};

exports.convertSecsToTimeString = convertSecsToTimeString;

const convertSecsToDurationString = value => {
  const duration = exports.convertSecsToDuration(value);

  if (!duration) {
    return null;
  }

  return `${duration.hour !== '00' ? duration.hour + 'h' : ''} ${duration.minute !== '00' ? duration.minute + 'm' : ''} ${duration.second !== '00' ? duration.second + 's' : ''}`;
};

exports.convertSecsToDurationString = convertSecsToDurationString;