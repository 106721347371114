"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollapsableContainer = void 0;

const react_1 = __importStar(require("react"));

const foundations_1 = require("../../foundations");

const __1 = require("..");

function CollapsableContainer({
  children,
  rowContent,
  hasDragHandle
}) {
  const [isOpen, setIsOpen] = react_1.useState(false);
  return react_1.default.createElement(__1.CollapsableContainerStyles.Wrapper, null, react_1.default.createElement(__1.CollapsableContainerStyles.Row, {
    "data-testid": "collapsable-container-row",
    onClick: () => setIsOpen(state => !state)
  }, react_1.default.createElement(foundations_1.Flex, {
    ml: hasDragHandle ? 'xxl' : 'm',
    justifyContent: "flex-start",
    flex: "1"
  }, rowContent), react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center",
    flex: "none",
    mx: "s"
  }, react_1.default.createElement(__1.Icon, {
    "data-testid": "collapsable-container-row-icon",
    name: isOpen ? 'TriangleDown' : 'TriangleRight'
  }))), isOpen && react_1.default.createElement(__1.CollapsableContainerStyles.CollapsableContent, {
    "data-testid": "collapsable-container-children"
  }, children));
}

exports.CollapsableContainer = CollapsableContainer;