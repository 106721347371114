"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Control = void 0;

const react_1 = __importStar(require("react"));

const react_select_1 = require("react-select");

const index_1 = require("../index");

const InputText_1 = require("../../InputText");

const SelectLabel = props => {
  const {
    selectProps,
    hasValue,
    isDisabled,
    isFocused,
    handleLabelClick
  } = props;
  const {
    placeholder,
    tooltip,
    label,
    required
  } = selectProps;
  const finalLabel = required && label ? label + ' *' : label;
  return react_1.default.createElement(index_1.SelectStyles.SelectLabel, {
    onClick: handleLabelClick,

    /**
     * @ts-expect-error */
    hasValue: hasValue || placeholder,
    isDisabled: isDisabled,
    // dont allow control to be focused if it's disabled
    isFocused: !isDisabled && isFocused,
    selectProps: selectProps,
    label: finalLabel,
    tooltip: tooltip
  }, finalLabel || '');
};

const Control = props => {
  const {
    selectProps,
    isDisabled,
    isFocused
  } = props;
  const {
    openMenu,
    tooltip
  } = selectProps;
  const handleLabelClick = react_1.useCallback(() => {
    if (openMenu && typeof openMenu === 'function') {
      openMenu();
    }
  }, [openMenu]);
  const renderLabel = react_1.useCallback(() => {
    if (tooltip) {
      return react_1.default.createElement("div", {
        onClick: handleLabelClick
      }, react_1.default.createElement(InputText_1.InputTooltip, {
        title: tooltip,
        position: "top-start",
        arrow: true
      }, react_1.default.createElement(SelectLabel, Object.assign({}, props, {
        handleLabelClick: handleLabelClick
      }))));
    }

    return react_1.default.createElement(SelectLabel, Object.assign({}, props, {
      handleLabelClick: handleLabelClick
    }));
  }, [handleLabelClick, props, tooltip]);
  return react_1.default.createElement(react_1.Fragment, null, react_1.default.createElement(react_select_1.components.Control // dont allow control to be focused if it's disabled
  , Object.assign({}, { ...props,
    isFocused: !isDisabled && isFocused
  })), renderLabel());
};

exports.Control = Control;