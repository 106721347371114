import React from 'react';

import { Verticals } from './pages/Verticals';
import { Dental } from './pages/Verticals/Dental/Dental';
import { Jewelry } from './pages/Verticals/Jewelry/Jewelry';
import { IOT } from './pages/Verticals/IOT/IOT';
import { MES } from './pages/Verticals/MES/MES';
import { Industrial } from './pages/Verticals/Industrial/Industrial';
import { Success } from './pages/Success/Success';
import { Canceled } from './pages/Canceled/Canceled';

export const routes = [
  {
    path: '/',
    Component: Verticals
  },
  {
    path: '/dental',
    Component: Dental
  },
  {
    path: '/jewelry',
    Component: Jewelry
  },
  {
    path: '/iot',
    Component: IOT
  },
  {
    path: '/mes',
    Component: MES
  },
  {
    path: '/industrial',
    Component: Industrial
  },
  {
    path: '/success',
    Component: Success
  },
  {
    path: '/canceled',
    Component: Canceled
  }
];

export const NotFound = () => <div>404, page not found!</div>;
