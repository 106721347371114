"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonGroup = void 0;

const react_1 = __importStar(require("react"));

const foundations_1 = require("../../foundations");

const ButtonGroup = ({
  children: _children,
  ...rest
}) => {
  const children = react_1.Children.toArray(_children);
  return react_1.default.createElement(foundations_1.Flex, Object.assign({
    justifyContent: "flex-end"
  }, rest), children && children.map((child, i) => {
    if (react_1.default.isValidElement(child)) {
      return react_1.default.cloneElement(child, {
        mr: i === children.length - 1 ? 0 : 'xs',
        size: 'l',
        mode: child.props.mode || 'secondary'
      });
    }

    return child;
  }));
};

exports.ButtonGroup = ButtonGroup;