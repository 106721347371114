"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformUrl = void 0;

const transformUrl = step => {
  const debugMode = window.location.hash === '#debug' || window.location.href.endsWith('#debug');

  if (window.location.href.indexOf('/batch') === -1) {
    let url = window.location.href.replace('#debug', '').toLowerCase();
    url = url.replace('step/', 'step');
    url = url.indexOf('step') > 0 ? url.slice(0, url.lastIndexOf('/')) : url;
    url = !url.endsWith('/') ? url + `/${step}` : url + step;
    if (debugMode) url = url + '#debug';
    window.history.replaceState(null, 'changed step', url);
  }
};

exports.transformUrl = transformUrl;