"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RadioGroup = void 0;

const react_1 = __importStar(require("react"));

const Radio_1 = require("../Radio");

const RadioGroup = ({
  allDisabled,
  name,
  onChange,
  options = [],
  selected
}) => {
  return react_1.default.createElement(react_1.Fragment, null, options.map((option, index) => {
    const {
      disabled,
      error,
      label,
      value,
      subLabel
    } = option;
    return react_1.default.createElement(Radio_1.Radio, {
      key: `Radio${index}`,
      error: error,
      tabIndex: index,
      label: label,
      subLabel: subLabel,
      name: name,
      value: value,
      disabled: disabled || allDisabled,
      selected: value === selected,
      onChange: onChange
    });
  }));
};

exports.RadioGroup = RadioGroup;