import React from 'react';
import { Heading, Box } from '@oqton/design-system';

import { Layout } from '../../components/Style';

export const Success = () => {
  return (
    <Layout width="960">
      <Box mb="l">
        <Heading>Thank you for trying Oqton!</Heading>
      </Box>
      We will contact you ASAP to discuss your company details so we can set up your own environment. Feel free to
      already chat with us using the widget in the lower right corner if you have any questions.
    </Layout>
  );
};
