"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableRowWrapper = void 0;

const react_1 = __importStar(require("react"));

const TableRow_1 = require("./TableRow");

const getIsSubRowOpen = row => {
  if (!(row === null || row === void 0 ? void 0 : row.subRowOpen)) {
    return false;
  }

  return row.subRowOpen;
};

const getSubRows = row => {
  if (!row || !row.subRows) {
    return [];
  }

  return row.subRows;
};

function TableRowWrapperComponent(props) {
  const {
    row,
    depth,
    ...rest
  } = props;
  const [isOpen, setIsOpen] = react_1.useState(getIsSubRowOpen(row));
  const subRows = getSubRows(row);

  if (subRows.length === 0) {
    return react_1.default.createElement(TableRow_1.TableRow, Object.assign({
      key: `TableRow-${depth}`,
      depth: depth,
      row: row
    }, rest));
  }

  return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(TableRow_1.TableRow, Object.assign({
    key: `TableRow-${depth}`,
    isOpen: isOpen,
    hasSubRow: true,
    depth: depth,
    row: row
  }, rest, {
    onClick: () => setIsOpen(!isOpen)
  })), isOpen && subRows.map((subRow, rowIndex) => {
    const _depth = depth + 1;

    if (getSubRows(subRow).length === 0) {
      return react_1.default.createElement(TableRow_1.TableRow, Object.assign({
        key: `TableRow-${_depth}-${rowIndex}`,
        depth: _depth,
        row: subRow
      }, rest));
    }

    return react_1.default.createElement(TableRowWrapperComponent, Object.assign({
      key: `TableRow-${_depth}-${rowIndex}`,
      depth: _depth,
      row: subRow
    }, rest));
  }));
}

exports.TableRowWrapper = react_1.memo(TableRowWrapperComponent);