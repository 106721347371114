"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMergedRef = void 0;

const useMergedRef = (...refs) => element => refs.forEach(ref => {
  if (typeof ref === 'function') ref(element);else if (ref && typeof ref === 'object') ref.current = element;
});

exports.useMergedRef = useMergedRef;