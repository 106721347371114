"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColorScalePicker = void 0;

const react_1 = __importStar(require("react"));

const useTranslation_1 = require("../../hooks/useTranslation");

const useTheme_1 = require("../../hooks/useTheme");

const Select_1 = require("../Select");

const getAccessibleHeatmapColorOptions = (t, gradients) => {
  return [{
    label: t('designSystem:components.colorPicker.brewer'),
    value: 'brewer',
    color: gradients.heatmapGradients.brewer
  }, {
    label: t('designSystem:components.colorPicker.heat'),
    value: 'heat',
    color: gradients.heatmapGradients.heat
  }, {
    label: t('designSystem:components.colorPicker.ggplot'),
    value: 'ggplot',
    color: gradients.heatmapGradients.ggplot
  }, {
    label: t('designSystem:components.colorPicker.brewerygb'),
    value: 'brewerygb',
    color: gradients.heatmapGradients.brewerygb
  }, {
    label: t('designSystem:components.colorPicker.viridis'),
    value: 'viridis',
    color: gradients.heatmapGradients.viridis
  }, {
    label: t('designSystem:components.colorPicker.magma'),
    value: 'magma',
    color: gradients.heatmapGradients.magma
  }];
};

const ColorScalePicker = props => {
  const {
    value,
    onColorChange
  } = props;
  const {
    t
  } = useTranslation_1.useTranslation();
  const {
    colors
  } = useTheme_1.useTheme();
  return react_1.default.createElement(react_1.Fragment, null, react_1.default.createElement(Select_1.Select, {
    label: t('designSystem:components.colorScalePicker.colorscale'),
    options: getAccessibleHeatmapColorOptions(t, colors.gradients),
    value: value,
    onChange: selected => {
      onColorChange(selected ? selected.value : null);
    }
  }));
};

exports.ColorScalePicker = ColorScalePicker;