import React from 'react';

import { Button, Box, Card, Text } from '@oqton/design-system';

import { useCheckout } from '../../../components/Checkout/useCheckout';

export const Total = ({ data, type }) => {
  const { error, busy, anyIot, monthlyTotal, handleCheckoutWithStripe } = useCheckout(data, type);

  return (
    <Card>
      <Box>
        <Text>Monthly total after 30 day trial:</Text> <b>EUR {monthlyTotal}</b>
      </Box>
      {anyIot && (
        <Box>
          <Text>One-time managed edge gateway deposit:</Text> <b>EUR {data.setup.edgeGatewaySetup.amount}</b>
        </Box>
      )}
      <Box>
        <Text>Yearly fee:</Text> <b>EUR {monthlyTotal * 12}</b>
      </Box>
      {error && <Box my="l">Error: {error}</Box>}
      <Box mt="l">
        <Button onClick={handleCheckoutWithStripe} disabled={busy}>
          {busy ? 'Redirecting to secure payment provider...' : 'Go To Checkout'}
        </Button>
        <Box mt="s">
          <Text mode="tertiary">Securely pay with Apple Pay, Visa, Mastercard and Amex.</Text>
        </Box>
      </Box>
    </Card>
  );
};
