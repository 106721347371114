"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberUnitDefinition = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const Formatters_1 = require("../Formatters");
/**
 * Numeric Unit Definition.
 */
class NumberUnitDefinition extends UnitDefinition_1.UnitDefinition {
    constructor(config) {
        super(config);
        this.scale = config.scale == null ? 1 : config.scale;
    }
    formatAsTokens(input, precision = this.precision, withSymbol = true) {
        const { symbol, scale } = this;
        if (input == null || isNaN(+input)) {
            return [];
        }
        return Formatters_1.formatNumberAsTokens(input * scale, precision, withSymbol ? symbol : undefined);
    }
}
exports.NumberUnitDefinition = NumberUnitDefinition;
