"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSpaceProps = exports.cursor = void 0;

const lodash_1 = require("lodash");

const cursor = props => props.cursor && `cursor: ${props.cursor};`;

exports.cursor = cursor;

const getSpaceProps = props => lodash_1.pick(props, ['m', 'mt', 'mr', 'mb', 'ml', 'mx', 'my', 'p', 'pt', 'pr', 'pb', 'pl', 'px', 'py']);

exports.getSpaceProps = getSpaceProps;