"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GlobalNavHamburger = void 0;

const react_1 = __importStar(require("react"));

const Button_1 = require("../Button");

const theme_1 = require("../../theme");

const GlobalNavContext_1 = require("../GlobalNav/GlobalNavContext");

const GlobalNavHamburger = ({
  buttonText
}) => {
  const {
    isCurrentBreakpointLargerThan
  } = theme_1.useResponsiveContext();
  const GlobalNavContextObject = react_1.useContext(GlobalNavContext_1.GlobalNavContext);

  if (!GlobalNavContextObject) {
    throw new Error('The GlobalNavContext is not set. ' + 'This is most likely because you have not set GlobalNavProvider' + "as a parent element of this route's layout.");
  }

  const {
    setOpen
  } = GlobalNavContextObject;

  if (isCurrentBreakpointLargerThan('xs')) {
    return react_1.default.createElement(Button_1.Button, {
      size: "l",
      onClick: () => setOpen(value => !value),
      icon: "Hamburger",
      text: buttonText || 'Menu',
      mode: "secondary",
      mr: "l"
    });
  }

  return react_1.default.createElement(Button_1.Button, {
    size: "l",
    onClick: () => setOpen(value => !value),
    icon: "Hamburger",
    mode: "secondary",
    mr: "l"
  });
};

exports.GlobalNavHamburger = GlobalNavHamburger;