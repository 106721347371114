"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useWindowSize = void 0;

const react_1 = require("react");

function useWindowSize() {
  const isClient = typeof window === 'object'; // Memoize window size function

  const getSize = react_1.useCallback(() => ({
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  }), [isClient]);
  const [windowSize, setWindowSize] = react_1.useState(getSize);
  react_1.useEffect(() => {
    function handleResize() {
      if (isClient) {
        setWindowSize(getSize());
      }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, // Empty array ensures that effect is only run on mount and unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return windowSize;
}

exports.useWindowSize = useWindowSize;