import React from 'react';

export const List = ({ items }) => {
  return (
    <ul style={{ paddingLeft: '16px', listStyleType: 'circle' }}>
      {items.map((v, i) => (
        <li key={`list${v}${i}`}>{v}</li>
      ))}
    </ul>
  );
};
