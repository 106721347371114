"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FixedRightColumnGrid = void 0;

const react_1 = __importStar(require("react"));

const foundations_1 = require("../../foundations");

const useResizeDetector_1 = require("../../hooks/useResizeDetector");

const RightColumn_1 = require("./RightColumn");

const constants_1 = require("./constants");

const FixedRightColumnGrid = ({
  leftContent,
  rightContent,
  expandableCanvas
}) => {
  const colRef = react_1.useRef();
  const {
    width
  } = useResizeDetector_1.useResizeDetector(colRef);
  const maxHeight = expandableCanvas ? '100%' : Math.ceil(width / 100 * 56.25);
  return react_1.default.createElement(foundations_1.Flex, {
    flex: "1",
    flexDirection: {
      xs: 'column',
      m: 'row'
    }
  }, react_1.default.createElement(foundations_1.Column, {
    ref: colRef,
    maxHeight: {
      xs: 'auto',
      m: maxHeight
    },
    width: {
      xs: '100%',
      m: `calc(100% - ${constants_1.RIGHT_COLUMN_WIDTH - 32}px)`
    }
  }, leftContent), react_1.default.createElement(RightColumn_1.RightColumn, {
    width: {
      xs: '100%',
      m: `${constants_1.RIGHT_COLUMN_WIDTH - 64}px`
    }
  }, rightContent));
};

exports.FixedRightColumnGrid = FixedRightColumnGrid;