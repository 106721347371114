"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debugMode = void 0;
/**
 * Check if user has appended #debug to the url
 * Or if server was started with DEBUG=true as command line variable
 *
 * @returns {boolean} Returns true if user appended #debug to the url
 */
const debugMode = () => (window && window.location.hash === '#debug') || (process.env.DEBUG && process.env.DEBUG === 'true') || false;
exports.debugMode = debugMode;
