"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CollapsibleButtonGroup = void 0;

const react_1 = __importStar(require("react"));

const useResizeDetector_1 = require("../../hooks/useResizeDetector");

const DropdownButton_1 = require("../DropdownButton");

const foundations_1 = require("../../foundations");

const hooks_1 = require("../../hooks");

const utils_1 = require("./utils");

const useCollapsibleButtonGroupSizes_1 = require("./useCollapsibleButtonGroupSizes");

const _1 = require(".");

const createMenuItem = button => {
  return {
    text: button.props.text,
    onClick: button.props.onClick,
    href: button.props.href,
    disabled: button.props.disabled,
    icon: button.props.icon,
    children: button.props.children
  };
};

const validateMenuItem = button => {
  var _a, _b;

  return typeof ((_a = button === null || button === void 0 ? void 0 : button.props) === null || _a === void 0 ? void 0 : _a.text) === 'string' && button.props.text.length > 0 || !!((_b = button === null || button === void 0 ? void 0 : button.props) === null || _b === void 0 ? void 0 : _b.children);
};

function CollapsibleButtonGroup({
  children,
  defaultDropdownMenuItems = [],
  ...rest
}) {
  const ref = react_1.useRef();
  const {
    spacing
  } = hooks_1.useTheme();
  const {
    width
  } = useResizeDetector_1.useResizeDetector(ref);

  const _children = react_1.Children.map(children, child => {
    if (child) {
      return child;
    }
  });

  const buttons = react_1.useMemo(() => {
    return react_1.Children.toArray(_children);
  }, [_children]);
  const {
    element,
    sizes,
    hasCalculatedSizes
  } = useCollapsibleButtonGroupSizes_1.useCollapsibleButtonGroupSizes({
    buttons,
    buttonSpacing: spacing.m
  });
  const [buttonList, menuItems] = react_1.useMemo(() => {
    let buttonsOutput = [];
    let itemsOutput = [];
    const startingButtons = react_1.Children.toArray(children).filter(b => validateMenuItem(b)); // if no width then not much point showing anything

    if (width > 0) {
      // work out the amount of buttons to keep
      const buttonsToKeep = utils_1.getAmountOfButtonsToKeep({
        widthsArray: sizes,
        buttonCount: startingButtons.length,
        hasDropdown: defaultDropdownMenuItems.length > 0,
        width
      }); // get an array of buttons that need to be menu items

      const menuItemArray = startingButtons.splice(buttonsToKeep); // add the buttons as menu items first before any defaults

      if (menuItemArray.length > 0) {
        itemsOutput.push({
          items: menuItemArray.map(createMenuItem)
        });
      } // add the defaults in at the bottom


      if (defaultDropdownMenuItems.length > 0) {
        itemsOutput = [...itemsOutput, ...defaultDropdownMenuItems];
      } // set buttons to keep to button list


      buttonsOutput = startingButtons.splice(0, buttonsToKeep);
    }

    return [buttonsOutput, itemsOutput]; // eslint-disable-next-line
  }, [width, _children, defaultDropdownMenuItems]);
  const buttonGroup = buttonList.map((button, i) => {
    return react_1.default.cloneElement(button, {
      mr: i === buttonList.length - 1 ? 0 : 'xs',
      size: 'l',
      mode: button.props.mode || 'secondary'
    });
  }); // check that subItems have length too otherwise we get empty dropdown

  if (menuItems.length > 0 && menuItems.some(group => group.items.length > 0)) {
    buttonGroup.push( // @ts-expect-error
    react_1.default.createElement(DropdownButton_1.DropdownButton, {
      key: "dropdown",
      icon: "MoreVertical",
      mode: "secondary",
      menuItems: menuItems
    }));
  }

  return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(foundations_1.Flex, Object.assign({
    "data-testid": "collapsible-button-group",
    ref: ref,
    justifyContent: "flex-end",
    flex: 1
  }, rest), hasCalculatedSizes && react_1.default.createElement(_1.ButtonGroup, null, buttonGroup)), element);
}

exports.CollapsibleButtonGroup = CollapsibleButtonGroup;