import React from 'react';
import styled from 'styled-components';

import { Flex, Box, OqtonLogo } from '@oqton/design-system';

const defaultWidth = 960;

const Background = styled(Box)`
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: ${p => p.theme.spacing.xl}px;
`;

const BackgroundBox = styled(Box).attrs({ frameStyle: 'bgStackPrimary' })`
  padding: ${p => p.theme.spacing.xl}px;
  margin: 0 auto;
  width: ${p => p.width || defaultWidth}px;
`;

const LogoBox = styled(Box)`
  margin: ${p => p.theme.spacing.xl}px auto;
  width: ${p => p.width || defaultWidth}px;
`;

const ExternalLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 12px;
`;

export const Layout = props => {
  return (
    <Background>
      <BackgroundBox>
        <Box {...props} />
      </BackgroundBox>
      <LogoBox>
        <Flex alignItems="flex-end">
          <OqtonLogo fill="#fff" />
          <Box ml="auto">
            <Flex>
              <Box ml="l">
                <ExternalLink href="https://www.oqton.com/privacy-statement/" target="_blank">
                  Privacy Policy
                </ExternalLink>
              </Box>
              <Box ml="l">
                <ExternalLink href="https://www.oqton.com/terms-of-use/" target="_blank">
                  Terms of use
                </ExternalLink>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </LogoBox>
    </Background>
  );
};
