"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputTextQA = void 0;

const testutils_1 = require("../../testutils");

const VisibilityControls_1 = require("../VisibilityControls");

class InputTextQA extends testutils_1.QA {
  get isActive() {
    return this.input.isActive;
  }
  /**
   * Get the html input element
   */


  get input() {
    return this.get(testutils_1.InputQA);
  }

  get value() {
    return this.input.value;
  }

  set value(value) {
    this.input.value = value;
  }

  get visibilityControls() {
    return this.get(VisibilityControls_1.VisibilityControlsQA);
  }

  click() {
    this.fireEvent('click');
  }

}

exports.InputTextQA = InputTextQA;
InputTextQA.componentName = 'InputText';
InputTextQA.selector = "[data-testid='InputText']";