import React from 'react';
import { Heading, Flex, Box, Button, Card, CardTitle, CardSubtitle, CardBody } from '@oqton/design-system';

import { Layout, Link } from '../../components/Style';

export const Verticals = () => {
  return (
    <Layout>
      <Box mb="l">
        <Card justifyContent="space-between">
          <Box>
            <CardTitle>30 day free trial</CardTitle>
            <CardSubtitle>
              Try Oqton risk free for 30 days. Pick a vertical such as dental, jewelry or industrial to automate your
              complete production engineering workflow, or pick a started kit to get started with Industry 4.0 by using
              Oqton as lightweight MES or IOT system. You can later always expand to more.
            </CardSubtitle>
          </Box>
        </Card>
      </Box>
      <Box mb="l">
        <Heading>Select a vertical</Heading>
      </Box>
      <Flex flexBasis="100%">
        <Box flex="1 1 0px">
          <Link to="/dental">
            <Card justifyContent="space-between">
              <Box>
                <CardTitle>Dental</CardTitle>
                <CardSubtitle>Starting at EUR 70 / month </CardSubtitle>
                <CardBody>
                  Oqton is ideally suited to manage and automate the complete dental production workflow, both for
                  metal/polymer 3D printing and CNC machining. Spend less time preparing files and improve your
                  production efficiency.
                </CardBody>
              </Box>
              <Button mt="s">Select Dental</Button>
            </Card>
          </Link>
        </Box>
        <Box mx="m" flex="1 1 0px">
          <Link to="/jewelry">
            <Card justifyContent="space-between">
              <Box>
                <CardTitle>Jewelry</CardTitle>
                <CardSubtitle>Starting at EUR 120 / month </CardSubtitle>
                <CardBody>
                  Oqton completely automates the build preparation and nesting for castable wax printing for jewelry.
                  Natively convert JewelCAD, recognize jewelry types and apply high precision automatically generated
                  support structures, drastically reducing engineering costs.
                </CardBody>
              </Box>
              <Button mt="s">Select Jewelry</Button>
            </Card>
          </Link>
        </Box>
        <Box flex="1 1 0px">
          <Link to="/industrial">
            <Card justifyContent="space-between">
              <Box>
                <CardTitle>Industrial/Medical</CardTitle>
                <CardSubtitle>Starting at EUR 130 / month </CardSubtitle>
                <CardBody>
                  Oqton is a manufacturing executing system with integrated document management. Includes integrated
                  CAM, build preparation and IOT platform. One-stop solution for metal/polymer 3D printing and CNC
                  machining to produce industrial and medical parts.
                </CardBody>
              </Box>
              <Button mt="s">Select Industrial</Button>
            </Card>
          </Link>
        </Box>
      </Flex>
      <Box my="l">
        <Heading>Or select a starter kit</Heading>
      </Box>
      <Flex flexBasis="100%">
        <Box flex="1 1 0px">
          <Link to="/mes">
            <Card justifyContent="space-between">
              <Box>
                <CardTitle>Lightweight MES starter kit</CardTitle>
                <CardSubtitle>Starting at EUR 299 and EUR 20 / month </CardSubtitle>
                <CardBody>
                  This lightweight MES starter kit has everything you need to start digitizing, optimizing and tracing
                  your production workflows, whatever you are producing. It includes Oqton, an NFC enabled ruggedized
                  mobile device and 10 NFC cards/stickers to digitally track production.
                </CardBody>
              </Box>
              <Button mt="s">Select MES starter kit</Button>
            </Card>
          </Link>
        </Box>
        <Box ml="m" flex="1 1 0px">
          <Link to="/iot">
            <Card justifyContent="space-between">
              <Box>
                <CardTitle>Industrial IOT starter kit</CardTitle>
                <CardSubtitle>Starting at EUR 80 / month </CardSubtitle>
                <CardBody>
                  Oqton is a single platform to monitor and analyze data from any IOT connected device. It has a
                  powerful dashboard and report builder, alert rules, and machine video streaming support. Includes a
                  managed edge device to securely connect your facility to the Oqton cloud.
                </CardBody>
              </Box>
              <Button mt="s">Select IOT starter kit</Button>
            </Card>
          </Link>
        </Box>
      </Flex>
    </Layout>
  );
};
