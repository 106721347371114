"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledDurationInput = void 0;

const react_1 = __importStar(require("react"));

const index_1 = require("../index");

const DatePicker_1 = require("../DatePicker");

const TimeInput_1 = require("./TimeInput");

const StyledDurationInput = ({
  children,
  disabled = false,
  dropdownIsOpen = false,
  hasDropdown = false,
  label = '',
  error,
  value,
  placeholder,
  onChange,
  onClear,
  onDropdownButtonClick,
  clearButtonRef,
  dropdownButtonRef,
  readOnly,
  format,
  pickerRef,
  tooltip,
  isHidden,
  isLocked,
  onClickHidden,
  onClickLock,
  ...rest
}) => {
  const [inValidInput, setInvalidInput] = react_1.useState(null);

  const handleOnClear = () => {
    if (onClear) {
      onClear(undefined);
    }
  };

  const handleInvalidInput = message => {
    setInvalidInput(message);
  }; // In case of no label, pass a non-breaking space into the label to keep error text aligned vertically


  const labelOrNBSP = label || '&nbsp;';
  return react_1.default.createElement(index_1.InputStyles.Wrapper, Object.assign({}, rest), react_1.default.createElement(index_1.InputTooltip, {
    title: tooltip || '',
    position: "top",
    arrow: true,
    disabled: !tooltip
  }, react_1.default.createElement(index_1.InputLabel, {
    tooltip: tooltip,
    disabled: disabled,
    label: label
  }, labelOrNBSP)), react_1.default.createElement(TimeInput_1.TimeInput, {
    pickerRef: pickerRef,
    value: value,
    disabled: disabled,
    onChange: onChange,
    maxDetail: "second",
    handleInvalidInput: handleInvalidInput,
    format: format,
    error: error || inValidInput,
    dropdownIsOpen: dropdownIsOpen
  }), error && react_1.default.createElement(index_1.InputError, null, error), react_1.default.createElement(DatePicker_1.Icons, null, !disabled && onClear && react_1.default.createElement(DatePicker_1.ClearButton, {
    tabIndex: -1,
    "data-testid": "clear-button",
    onClick: handleOnClear,
    type: "button",
    ref: clearButtonRef
  }, react_1.default.createElement(index_1.Icon, {
    name: "TextClear"
  })), react_1.default.createElement(index_1.VisibilityControls, {
    disabled: disabled,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  }), hasDropdown && react_1.default.createElement(DatePicker_1.InputDropdownButton, {
    tabIndex: -1,
    disabled: disabled,
    "data-testid": "dropdown-button",
    onClick: onDropdownButtonClick,
    type: "button",
    ref: dropdownButtonRef
  }, react_1.default.createElement(index_1.Icon, {
    name: "TextCaretDown"
  }))), children);
};

exports.StyledDurationInput = StyledDurationInput;