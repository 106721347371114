import React from 'react';
import { Link as LinkWithUnderline } from 'react-router-dom';
import ReactGA from 'react-ga';

export const Link = props => {
  return (
    <LinkWithUnderline
      style={{ color: 'inherit', textDecoration: 'inherit' }}
      {...props}
      onClick={() => {
        ReactGA.pageview(props.to);
      }}
    />
  );
};
