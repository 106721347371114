export const config =
  process.env.NODE_ENV === 'development'
    ? {
        api: {
          host: 'http://oqton.local:8748'
        },
        stripe: {
          key: 'pk_test_uzZUCLtwNsHgo8ioTWC0t6nT00ipXduNhb'
        },
        googleAnalytics: {
          key: 'UA-107591394-1'
        }
      }
    : {
        api: {
          host: ''
        },
        stripe: {
          key: 'pk_live_sy7nbLG4w7hoqf16XDH49S0t00wNjhYepU'
        },
        googleAnalytics: {
          key: 'UA-107591394-1'
        }
      };
