import {
  Box,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Flex,
  GuidanceBox,
  Image,
  InputNumber,
} from '@oqton/design-system';
import { setIn } from '@oqton/utils-core';
import React, { useState } from 'react';
import { useCheckout } from '../../../components/Checkout/useCheckout';
import { Layout, List } from '../../../components/Style';
import { Total } from '../components/Total';
import defaultIndustrialData from '../data/industrial.json';
import { IndustrialCAM } from './IndustrialCAM';

const VERTICALTYPE = 'industrial';

import { isPositiveFloat } from '../../../utils/validators';

export const Industrial = () => {
  const [data, setData] = useState(defaultIndustrialData);
  const { anyIot } = useCheckout(data, VERTICALTYPE);

  return (
    <Layout width="540">
      <Box mb="l">
        <Card>
          <Flex>
            <Box mr="xl">
              <Image maxWidth="300" src="/images/factoryos.png" />
            </Box>
            <Box>
              <CardTitle>Oqton platform</CardTitle>
              <CardSubtitle>EUR {data.monthly.fos.amount} per user per month</CardSubtitle>
              <CardBody>
                <List
                  items={[
                    'Order tracking',
                    'Production execution',
                    'Automated scheduling',
                    'Full traceability',
                    'Production dashboards, reports and alerts',
                  ]}
                />
              </CardBody>
              <GuidanceBox mb="l">Oqton is the required core platform, select the number of users.</GuidanceBox>
              <Box>
                <InputNumber
                  type="number"
                  label="Number of users (EUR 20 per user per month)"
                  onValidValueChange={(v) => setData(setIn(data, 'monthly.fos.quantity', v))}
                  value={data.monthly.fos.quantity}
                  validator={isPositiveFloat}
                />
              </Box>
            </Box>
          </Flex>
        </Card>
      </Box>
      <Box mb="l">
        <IndustrialCAM data={data} setData={setData} />
      </Box>
      <Box mb="l">
        <Card>
          <Flex>
            <Box mr="xl">
              <Image maxWidth="300" src="/images/machine_monitoring.png" />
            </Box>
            <Box>
              <CardTitle>Machine monitoring</CardTitle>
              <CardSubtitle>Starting at EUR {data.monthly.iotSmall.amount} per machine per month</CardSubtitle>
              <CardBody>
                <List
                  items={[
                    'Real-time machine status, accessible anywhere on any device',
                    'Create custom machine dashboards and reports',
                    'Send alerts to SMS or email',
                    'Machine image and video support',
                  ]}
                />
              </CardBody>
              <GuidanceBox mb="l">Select the number of machines of each category you want to connect.</GuidanceBox>
              <Box>
                <InputNumber
                  type="number"
                  label={`Small machines - 1GB / month (EUR ${data.monthly.iotSmall.amount} per machine per month)`}
                  onValidValueChange={(v) => setData(setIn(data, 'monthly.iotSmall.quantity', v))}
                  value={data.monthly.iotSmall.quantity}
                  validator={isPositiveFloat}
                />
              </Box>
              <Box>
                <InputNumber
                  type="number"
                  label={`Medium machines - 3GB / month (EUR ${data.monthly.iotMedium.amount} per machine per month)`}
                  onValidValueChange={(v) => setData(setIn(data, 'monthly.iotMedium.quantity', v))}
                  value={data.monthly.iotMedium.quantity}
                  validator={isPositiveFloat}
                />
              </Box>
              <Box>
                <InputNumber
                  type="number"
                  label={`Large machines - 7GB / month (EUR ${data.monthly.iotLarge.amount} per machine per month)`}
                  onValidValueChange={(v) => setData(setIn(data, 'monthly.iotLarge.quantity', v))}
                  value={data.monthly.iotLarge.quantity}
                  validator={isPositiveFloat}
                />
              </Box>
            </Box>
          </Flex>
        </Card>
      </Box>
      {anyIot && (
        <Box mb="l">
          <Card>
            <Flex>
              <Box mr="xl">
                <Image maxWidth="300" src="/images/edge.png" />
              </Box>
              <Box>
                <CardTitle>Managed edge gateway</CardTitle>
                <CardSubtitle>
                  One-time EUR {data.setup.edgeGatewaySetup.amount} deposit, EUR{' '}
                  {data.monthly.edgeGatewayMonthly.amount} per month
                </CardSubtitle>
                <CardBody>
                  <List
                    items={[
                      'Secure cloud gateway and local caching',
                      'Interfaces to most industrial IOT standards',
                      'Auto-download production files and video/image upload',
                      'Fully cloud managed',
                      'Easy self install',
                    ]}
                  />
                </CardBody>
                <GuidanceBox mb="l">Required when adding machine monitoring.</GuidanceBox>
              </Box>
            </Flex>
          </Card>
        </Box>
      )}
      <Total data={data} type={VERTICALTYPE} />
    </Layout>
  );
};
