import React from 'react';
import { Heading, Box, Button } from '@oqton/design-system';

import { Layout, Link } from '../../components/Style';

export const Canceled = () => {
  return (
    <Layout width="960">
      <Box mb="l">
        <Heading>Order Canceled...</Heading>
      </Box>
      Please try again or chat with us using the widget in the lower right corner if you have any further problems.
      <Box mt="l">
        <Link to="/">
          <Button>Back</Button>
        </Link>
      </Box>
    </Layout>
  );
};
