"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assertNotNullOrUndefined = exports.assert = void 0;
const object_1 = require("./object");
function assert(input, message = 'Assertion Failed') {
    if (!input) {
        throw new Error(message);
    }
}
exports.assert = assert;
const assertNotNullOrUndefined = (value) => {
    if (!object_1.isNotNullOrUndefined(value))
        throw new Error('value is null or undefined');
};
exports.assertNotNullOrUndefined = assertNotNullOrUndefined;
