"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = void 0;

const polished_1 = require("polished");

exports.colors = {
  primary: '#234057',
  primaryOpacity: '#23405759',
  secondary: '#59747A',
  tertiary: '#A8B7BF',
  inversePrimary: '#FFFFFF',
  inverseSecondary: 'rgba(255, 255, 255, 0.85)',
  inverseTertiary: 'rgba(255, 255, 255, 0.65)',
  accent: '#3BB3DB',
  disabled: '#E1E3E4',
  accentRed: '#F02542',
  white: '#FFFFFF',
  black: '#000000',
  borderPrimary: '#1F3B51',
  borderSecondary: '#D2D8D9',
  borderTertiary: '#ECECEC',
  backgroundPrimary: '#FFFFFF',
  backgroundSecondary: '#FBFBFA',
  backgroundTertiary: '#F8F7F6',
  bgStackSecondary: 'rgba(35, 64, 87, 0.05)',
  bgStackTertiary: '#FBFBFA',
  buttonColors: {
    primary: {
      background: '#3BB3DB',
      text: '#FFFFFF',
      border: '#FFFFFF'
    },
    secondary: {
      background: 'transparent',
      text: '#234057',
      border: '#D2D8D9'
    },
    tertiary: {
      background: 'transparent',
      text: '#234057',
      border: 'none'
    }
  },
  status: {
    error: '#F02542',
    warning: '#FA943B',
    executing: '#79C71E',
    paused: '#FFDC19',
    setup: '#B74CBD',
    ready: '#00708C',
    unknown: '#D2D8D9',
    plannedDowntime: '#F5831E',
    finished: '#3BB3DB',
    success: '#79C71E',
    inProgress: '#3BB3DB'
  },
  severity: {
    s1: '#F02542',
    s2: '#FF8200',
    s3: '#FAC815',
    s4: '#7AC2DF'
  },
  systemStatus: {
    default: {
      color: '#234057',
      backgroundColor: '#D2D8D9'
    },
    error: {
      color: '#FFFFFF',
      backgroundColor: '#F02542'
    },
    network_error: {
      color: '#FFFFFF',
      backgroundColor: '#F02542'
    },
    processing: {
      color: '#FFFFFF',
      backgroundColor: '#79C71E'
    },
    pending: {
      color: '#FFFFFF',
      backgroundColor: '#B74CBD'
    },
    warning: {
      color: '#FFFFFF',
      backgroundColor: '#FA943B'
    },
    info: {
      color: '#59747A',
      backgroundColor: '#D2D8D9'
    }
  },
  consoleStatus: {
    error: {
      color: '#F02542',
      iconDisabled: polished_1.transparentize(0.9, '#F02542')
    },
    warning: {
      color: '#FA943B',
      iconDisabled: polished_1.transparentize(0.7, '#FA943B')
    },
    info: {
      color: '#D2D8D9',
      iconDisabled: polished_1.transparentize(0.9, '#59747A')
    }
  },
  toggleColors: {
    default: {
      border: '#D2D8D9',
      borderChecked: '#234057',
      borderDisabled: '#D2D8D9',
      backgroundUnchecked: 'transparent',
      backgroundChecked: '#234057',
      backgroundDisabledUnchecked: '#E1E3E4',
      backgroundDisabledChecked: '#D2D8D9',
      buttonBackground: '#FFFFFF',
      outlineFocus: '#B1E1F1'
    },
    small: {
      border: '#D2D8D9',
      borderChecked: '#234057',
      borderDisabled: '#D2D8D9',
      backgroundUnchecked: 'transparent',
      backgroundChecked: '#234057',
      backgroundDisabledUnchecked: '#E1E3E4',
      backgroundDisabledChecked: '#D2D8D9',
      buttonBackground: '#FFFFFF',
      outlineFocus: '#d2d8d9'
    }
  },
  checkboxColors: {
    background: 'transparent',
    border: '#D2D8D9',
    borderFocused: '#234057',
    check: '#234057',
    outline: '#3BB3DB',
    outlineActive: '#234057',
    backgroundDisabled: '#E1E3E4',
    borderDisabled: '#E1E3E4',
    checkDisabled: '#A8B7BF',
    outlineDisabled: 'transparent'
  },
  chartColors: {
    red: ['#660C13', '#821019', '#CA0011', '#F02542', '#FF6077', '#FF949D', '#FFB8BD'],
    orange: ['#663408', '#9C5312', '#CC6D18', '#F5831E', '#FFA250', '#FFB573', '#FFCFA4'],
    yellow: ['#6F6114', '#BFA512', '#E3C311', '#FFDC19', '#FFE65B', '#FFEE90', '#FEF4BA'],
    green: ['#315408', '#4B7F0D', '#6AA921', '#79C71E', '#97DE44', '#BCF47A', '#D6FFA7'],
    teal: ['#07493D', '#016352', '#097C68', '#299380', '#3FBDA6', '#7BD8C7', '#B0F3E7'],
    blue: ['#1F596C', '#1B7390', '#3394B5', '#3BB3DB', '#6BD0F1', '#8DDBF5', '#B5EAFC'],
    indigo: ['#26135E', '#31187D', '#4B23C2', '#6E46E8', '#8160E6', '#9F83F4', '#D6C8FF'],
    violet: ['#411652', '#532068', '#722E8E', '#9135B7', '#A95CC8', '#CF87EC', '#DFB7EF'],
    pink: ['#6F0045', '#8E115F', '#AE2279', '#E156AD', '#E97DC1', '#F295CF', '#FBADDD'],
    gray: ['#232E31', '#344548', '#465C61', '#68777A', '#80979D', '#91A7AC', '#B5C0C2'],
    brewer: ['#06215C', '#193D77', '#285B94', '#367AB1', '#429BCF', '#79B2DB', '#A4C9E7', '#CDE1F3', '#F4FAFF'],
    heat: ['#FF0000', '#FF4600', '#FF6600', '#FF8100', '#FF9800', '#FFAC2F', '#FFC773', '#FFE3B0', '#FFFFEE'],
    ggplot: ['#0C2035', '#102E4B', '#143D62', '#184C79', '#1B5C92', '#1E6CAB', '#217DC6', '#238EE1', '#25A0FC'],
    brewerygb: ['#0A1149', '#183464', '#1E5980', '#1B809D', '#00A9BA', '#2EB9BD', '#89D0C2', '#C6E8C6', '#FEFFCA'],
    viridis: ['#3A0045', '#2B2053', '#1D3F61', '#0E5F6F', '#007B7B', '#40965C', '#7FB13E', '#BFCC1F', '#FEE700'],
    magma: ['#000006', '#240B38', '#40006A', '#7E0069', '#B50068', '#DB4258', '#FF6C43', '#FFB974', '#F9FFA8']
  },
  gradients: {
    menu: 'linear-gradient(0deg, #012233 0%, #0C6D8D 100%)',
    docsMenu: 'linear-gradient(37.32deg, #0d5076 10%, #C10146 92.83%)',
    heatmapGradients: {
      brewer: 'linear-gradient(90deg, #25a0fc 0%, #0c2035 100%)',
      heat: 'linear-gradient(269deg, #ff0000 0%, #ff9800 50%, #ffffee 100%)',
      ggplot: 'linear-gradient(90deg, #25a0fc 0%, #0c2035 100%);',
      brewerygb: 'linear-gradient(90deg, #feffca 0%, #2eb9bd 41%, #00a9ba 50%, #0a1149 100%)',
      viridis: 'linear-gradient(269deg, #3a0045 0%, #007b7b 50%, #fee600 100%)',
      magma: 'linear-gradient( 269deg, #000006 0%, #40006a 25%, #b50068 50%, #ff6c43 76%, #f9ffa8 100%)'
    }
  }
};