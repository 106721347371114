"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TestableController = void 0;

const react_1 = __importStar(require("react"));

const TestableContext_1 = require("./TestableContext");
/**
 * A light weight context to turn field testable component wrappers on
 * testing is true by default so you don't have to add a prop to turn it on, the assumption is you wouldn't add this context to the main app and only use it during a test
 */


function TestableController({
  testing = true,
  showHidden = true,
  children
}) {
  const state = react_1.useMemo(() => {
    return {
      testing,
      showHidden
    };
  }, [testing, showHidden]);
  return react_1.default.createElement(TestableContext_1.TestableContext.Provider, {
    value: state
  }, children);
}

exports.TestableController = TestableController;