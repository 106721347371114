"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translate = void 0;
const locales_1 = require("@oqton/locales");
/**
 * Simple string translator.
 * @param input The string to translate.
 */
function translate(input) {
    return locales_1.i18n.t(input);
}
exports.translate = translate;
