"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTheme = exports.baseTheme = void 0; // For full theme documentation, see:
// https://github.com/jxnblk/styled-system

const lodash_1 = require("lodash");

const foundations_1 = require("../foundations");

exports.baseTheme = {
  colors: foundations_1.colors,
  typography: foundations_1.typography,
  elevation: foundations_1.elevation,
  spacing: foundations_1.spacing,
  breakpoints: foundations_1.breakpoints
};

const createTheme = (overrideTheme = {}) => {
  const mergedTheme = lodash_1.merge(exports.baseTheme, overrideTheme);
  mergedTheme.frames = foundations_1.createFrameStyles(mergedTheme.colors);
  mergedTheme.space = mergedTheme.spacing;
  return mergedTheme;
};

exports.createTheme = createTheme;