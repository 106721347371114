"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DurationPicker = void 0;

const react_1 = __importStar(require("react"));

const lodash_1 = require("lodash");

const foundations_1 = require("../../foundations");

const index_1 = require("../index");

const dateTimePickerHelpers_1 = require("../../utils/dateTimePickerHelpers");

const Input_1 = require("../Input");

const components_1 = require("./components");

const utils_1 = require("./utils");

const index_2 = require("./index");

const DurationPicker = ({
  disabled,
  onFocus,
  onChange,
  value,
  validator,
  error,
  onValidValueChange,
  align = 'left',
  format = 'h:m:s',
  label = '',
  hasDropdown = true,
  mb = 'm',
  ...rest
}) => {
  const dropdownButtonRef = react_1.useRef(null);
  const pickerRef = react_1.useRef(null);
  const panelRef = react_1.useRef(null);
  const [open, setOpen] = react_1.useState(false);
  const handleClick = react_1.useCallback(e => {
    if (disabled) {
      return e.preventDefault();
    }

    const panelNode = panelRef.current;
    const dropdownButtonNode = dropdownButtonRef.current;
    const pickerNode = pickerRef.current; // Keep open if clicking again on the masked input whilst open

    if (e.target.type === 'text' && open) {
      e.preventDefault();
      return;
    }

    if (hasDropdown) {
      if (dropdownButtonNode && dropdownButtonNode.contains(e.target) || e.target === pickerNode) {
        setOpen(!open);
        e.preventDefault();
        return;
      } // Open (or keep open) the panel if you click on it


      if (panelNode && panelNode.contains(e.target)) {
        setOpen(true);
        e.preventDefault();
        return;
      } // If the click is anywhere else, close the panel


      setOpen(false);
    } // Toggle the panel open state if you click on the dropdown button

  }, [disabled, dropdownButtonRef, hasDropdown, open, panelRef, pickerRef]);
  const validationError = react_1.useMemo(() => {
    if (lodash_1.isFunction(validator)) {
      const validity = validator(value);
      if (validity instanceof Input_1.ValidationError) return validity;
    }
  }, [validator, value]);
  react_1.useEffect(() => {
    if (typeof window !== `undefined`) {
      if (open) {
        document.addEventListener('mousedown', handleClick, false);
      }
    }

    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('mousedown', handleClick, false);
      }
    };
  }, [handleClick, open]);

  const updateTime = (division, val) => {
    const secs = format !== 'd:h:m:s' ? utils_1.getTotalSeconds(division, val, value) : utils_1.getTotalSecondsDays(division, val, value);

    if (secs) {
      handleChange(secs);
    }
  };

  const handleChange = react_1.useMemo(() => {
    return value => {
      // always display user input
      if (lodash_1.isFunction(validator)) {
        if (onValidValueChange) {
          const val = validator(value);
          if (!(val instanceof Input_1.ValidationError)) onValidValueChange(val !== undefined ? val : value);
        }
      }

      if (onChange) onChange(value); // always call the original onChange, regardless of value validity
    };
  }, [validator, onChange, onValidValueChange]);
  let finalError = undefined;

  if (validationError) {
    finalError = error || validationError.error;
  }

  return react_1.default.createElement(foundations_1.Box, {
    mb: mb,
    onMouseDown: handleClick
  }, react_1.default.createElement(index_2.StyledDurationInput, Object.assign({
    format: format,
    disabled: disabled,
    hasDropdown: true,
    dropdownIsOpen: open,
    pickerRef: pickerRef,
    dropdownButtonRef: dropdownButtonRef,
    value: format !== 'd:h:m:s' ? utils_1.convertSecsToTimeString(value) : utils_1.convertSecsToTimeStringDays(value),
    onChange: handleChange,
    label: label,
    mb: 0,
    error: finalError
  }, rest)), open && react_1.default.createElement(index_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    // if (!(parentRect instanceof DOMRect)) return null; // FAILS ON TEST because DOMRect undefined
    if (!parentRect || parentRect.top === undefined) return null;
    return react_1.default.createElement(index_2.DurationPickerStyles.PanelWrapper, {
      "data-testid": "panel-wrap",
      ref: panelRef,
      parentRect: parentRect
    }, react_1.default.createElement(components_1.TimePickerPanel, {
      align: align,
      onDaySelect: day => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.day, day);
      },
      onHourSelect: hour => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.hour, hour);
      },
      onMinuteSelect: minute => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.minute, minute);
      },
      onSecondSelect: second => {
        updateTime(dateTimePickerHelpers_1.TIME_DIVISIONS.second, second);
      },
      format: format,
      international: true,
      value: format === 'd:h:m:s' ? utils_1.convertSecsToTimeStringDays(value) : utils_1.convertSecsToTimeString(value)
    }));
  }));
};

exports.DurationPicker = DurationPicker;