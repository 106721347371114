"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePosition = void 0;

const react_1 = require("react");

const useWindowSize_1 = require("../../hooks/useWindowSize");

let root;

if (typeof window !== `undefined`) {
  root = document.getElementById('root') || document.getElementById('___gatsby');
}

const usePosition = ({
  contentRef,
  parentRect,
  placement = 'TOP'
}) => {
  const [position, setPosition] = react_1.useState({
    bottom: 'auto',
    top: 'auto',
    left: 'auto',
    right: 'auto'
  });
  const windowSize = useWindowSize_1.useWindowSize();
  react_1.useEffect(() => {
    if (contentRef.current && (windowSize === null || windowSize === void 0 ? void 0 : windowSize.height) && root) {
      const _position = { ...position
      }; // we have to do this because of the animation it gets measured on enter

      const offset = contentRef.current.getBoundingClientRect().width - parentRect.width;

      switch (placement) {
        case 'TOP':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - offset / 2}px`;
          setPosition(_position);
          break;

        case 'TOP_LEFT':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left}px`;
          setPosition(_position);
          break;

        case 'TOP_RIGHT':
          _position.bottom = `${windowSize.height - parentRect.top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left - offset}px`;
          setPosition(_position);
          break;

        case 'BOTTOM':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - offset / 2}px`;
          setPosition(_position);
          break;

        case 'BOTTOM_LEFT':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left}px`;
          setPosition(_position);
          break;

        case 'BOTTOM_RIGHT':
          _position.top = `${parentRect.bottom - root.getBoundingClientRect().top + 5}px`;
          _position.left = `${parentRect.left - root.getBoundingClientRect().left - offset}px`;
          setPosition(_position);
          break;

        default:
          break;
      }
    } // eslint-disable-next-line

  }, [contentRef.current, parentRect.top, parentRect.bottom, parentRect.left]);
  return position;
};

exports.usePosition = usePosition;