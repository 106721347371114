"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownButton = void 0;

const react_1 = __importStar(require("react"));

const dropdownHelpers_1 = require("../../utils/dropdownHelpers");

const __1 = require("..");

const Portal_1 = require("../Portal");

const DropdownButtonStyles_1 = require("./DropdownButtonStyles");

exports.DropdownButton = react_1.forwardRef((props, ref) => {
  const {
    align = 'right',
    mode = 'secondary',
    menuWidth,
    loading = false,
    menuItems = [],
    width,
    text,
    onClose,
    onOpen,
    size,
    disabled,
    icon,
    buttonGroupRef,
    isOpen = false,
    usePortal = false,
    iconFill,
    menuHorizontalAlign,
    menuHorizontalOffset,
    menuVerticalAlign,
    menuVerticalOffset,
    ...rest
  } = props;
  const [isMenuOpen, setIsMenuOpen] = react_1.useState(false);
  const [id] = react_1.useState(dropdownHelpers_1.getUniqueId());
  const outerElement = react_1.useRef(null);

  const toggleOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = react_1.useCallback(() => {
    setIsMenuOpen(false);

    if (onClose) {
      onClose();
    }
  }, [onClose]);
  react_1.useEffect(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen]);
  react_1.useEffect(() => {
    const closeIfBlur = e => {
      const {
        current
      } = outerElement;

      if (current !== null && !current.contains(e.target)) {
        closeMenu();
      }
    };

    if (isMenuOpen && onOpen) onOpen();

    if (typeof window !== `undefined`) {
      document.body.addEventListener('click', closeIfBlur);
    }

    return () => {
      if (typeof window !== `undefined`) {
        document.body.removeEventListener('click', closeIfBlur);
      }
    };
  }, [closeMenu, isMenuOpen, onClose, onOpen]);

  const handleOnButtonKeyDown = e => {
    const {
      current
    } = outerElement;

    switch (e.key) {
      case 'ArrowDown':
        if (!isMenuOpen) {
          toggleOpen();
        }

        if (isMenuOpen && current !== null) {
          current.querySelectorAll('button')[1].focus();
        }

        break;

      case 'ENTER':
        if (!isMenuOpen) {
          toggleOpen();
        }

        if (isMenuOpen && current !== null) {
          current.querySelectorAll('button')[1].focus();
        }

        break;

      case 'ArrowUp':
        if (isMenuOpen) {
          toggleOpen();
        }

        break;

      default:
        break;
    }
  };

  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation(); // Prevent button from focusing onClick
    // this is already done on buttons but not on the trigger used here for the icon only button
    // if e.detail !== 0 it means a return was fired on a button so we keep the button focussed

    if (ref && ref.current && e.detail !== 0) {
      ref.current.blur();
    }

    toggleOpen();
  };

  const DropdownListComponent = () => usePortal ? react_1.default.createElement(Portal_1.Portal, {
    maintainPosition: true
  }, parentRect => {
    // if (!(parentRect instanceof DOMRect)) return null; // FAILS ON TEST because DOMRect undefined
    if (!parentRect || parentRect.top === undefined) return null;
    return react_1.default.createElement(DropdownButtonStyles_1.PanelWrapper, {
      parentRect: parentRect,
      ref: outerElement
    }, react_1.default.createElement(__1.DropdownList, {
      "aria-labelledby": `dropdown-button-${id}`,
      menuItems: menuItems,
      align: align,
      listWidth: menuWidth,
      onSelect: closeMenu,
      menuHorizontalAlign: menuHorizontalAlign,
      menuVerticalAlign: menuVerticalAlign,
      menuHorizontalOffset: menuHorizontalOffset,
      menuVerticalOffset: menuVerticalOffset
    }));
  }) : react_1.default.createElement(__1.DropdownList, {
    "aria-labelledby": `dropdown-button-${id}`,
    menuItems: menuItems,
    align: align,
    listWidth: menuWidth,
    onSelect: closeMenu,
    menuHorizontalAlign: menuHorizontalAlign,
    menuVerticalAlign: menuVerticalAlign,
    menuHorizontalOffset: menuHorizontalOffset,
    menuVerticalOffset: menuVerticalOffset
  });

  return react_1.default.createElement(DropdownButtonStyles_1.Wrapper, Object.assign({}, rest, {
    ref: outerElement
  }), react_1.default.createElement(__1.Button, {
    ref: ref,
    "data-testid": "dropdown-button",
    id: `dropdown-button-${id}`,
    "aria-haspopup": "true",
    "aria-expanded": isOpen,
    mode: mode,
    onClick: handleOnClick,
    icon: icon || loading && 'LoadingIndeterminateRing' || undefined,
    onKeyDown: handleOnButtonKeyDown,
    text: text,
    size: size,
    width: width,
    disabled: disabled || menuItems.length === 0,
    buttonGroupRef: buttonGroupRef,
    iconFill: iconFill
  }), isMenuOpen && react_1.default.createElement(DropdownListComponent, null));
});