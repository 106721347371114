"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.spacing = void 0;
exports.spacing = {
  none: 0,
  xxxs: 2,
  xxs: 4,
  xs: 8,
  s: 12,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 48,
  xxxl: 64,
  xxxxl: 96 // 9

};