import {
  Box,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  CellText,
  CollapsableContainer,
  Flex,
  GuidanceBox,
  Image,
  InputNumber,
  Table,
} from '@oqton/design-system';
import { setIn } from '@oqton/utils-core';
import React from 'react';
import { List } from '../../../components/Style';

import { isPositiveFloat } from '../../../utils/validators';

export const IndustrialCAM = ({ data, setData }) => {
  return (
    <Card>
      <Flex>
        <Box mr="xl">
          <Image maxWidth="300" src="/images/dental_cam.png" />
        </Box>
        <Box>
          <CardTitle>CAM for Industrial</CardTitle>
          <CardSubtitle>Starting at EUR {data.monthly.camSmall.amount} per machine per month</CardSubtitle>
          <CardBody>
            <List
              items={[
                'Import, healing and part classification',
                'Automated build preparation for metal/polymer 3D printing',
                'High density nesting',
                'Automated CAM for machining',
                'Hybrid additive/subtractive manufacturing',
                'Integrated post processors',
              ]}
            />
            <Box my="l">
              <CollapsableContainer rowContent={<div>Supported Machines</div>}>
                <Table
                  rows={[
                    {
                      type: 'Additive',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint1000',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint2000',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Sisma',
                      name: 'MYSINT100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Sisma',
                      name: 'EVEMET200',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EOS',
                      name: 'M100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EOS',
                      name: 'M280 / M290',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'M100T',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'M150',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'EP-3500 / EP-A350 / EP-A450',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'EP-A650',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: '',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: '3DSystems',
                      name: 'ProX Flex 100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: '3DSystems',
                      name: 'ProX DMP 200',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Renishaw',
                      name: 'AM250 / AM400 / RenAM 500',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 125HL',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 280HL',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Apollo / Apollo Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Aureus / Aureus Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Vida / Vida HD',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Micro Plus HD / XL / Advantage',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'P3 / P4',
                      category: 'Medium',
                    },
                    {
                      type: 'Subtractive',
                      manufacturer: 'Imes Icore',
                      name: '350i',
                      category: 'Medium',
                    },
                  ]}
                  columns={[
                    {
                      flex: '1 0 100px',
                      Header: 'Type',
                      Cell: (row) => <CellText text={row.type} />,
                    },
                    {
                      flex: '1 0 100px',
                      Header: 'Manufacturer',
                      Cell: (row) => <CellText text={row.manufacturer} />,
                    },
                    {
                      flex: '2 0 200px',
                      Header: 'Model',
                      Cell: (row) => <CellText text={row.name} />,
                    },
                    {
                      flex: '2 0 200px',
                      Header: 'Category',
                      Cell: (row) => <CellText text={row.category} />,
                    },
                  ]}
                />
              </CollapsableContainer>
            </Box>
          </CardBody>
          <GuidanceBox mb="l">
            Select the number of machines of each category you want to be able to prepare files for.
          </GuidanceBox>
          <Box>
            <InputNumber
              type="number"
              label={`Small machines (EUR ${data.monthly.camSmall.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camSmall.quantity', v))}
              value={data.monthly.camSmall.quantity}
              validator={isPositiveFloat}
            />
          </Box>
          <Box>
            <InputNumber
              type="number"
              label={`Medium machines (EUR ${data.monthly.camMedium.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camMedium.quantity', v))}
              value={data.monthly.camMedium.quantity}
              validator={isPositiveFloat}
            />
          </Box>
          <Box>
            <InputNumber
              type="number"
              label={`Large machines (EUR ${data.monthly.camLarge.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camLarge.quantity', v))}
              value={data.monthly.camLarge.quantity}
              validator={isPositiveFloat}
            />
          </Box>
        </Box>
      </Flex>
    </Card>
  );
};
