"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DurationPickerMS = void 0;

const react_1 = __importStar(require("react"));

const foundations_1 = require("../../foundations");

const StyledDurationMSInput_1 = require("./StyledDurationMSInput");

const durationHelpers_1 = require("./utils/durationHelpers");

const DurationPickerMS = ({
  disabled,
  onFocus,
  onChange,
  value,
  align = 'left',
  label = '',
  mb = 'm',
  ...rest
}) => {
  const pickerRef = react_1.useRef(null);
  return react_1.default.createElement(foundations_1.Box, {
    mb: mb
  }, react_1.default.createElement(StyledDurationMSInput_1.StyledDurationMSInput, Object.assign({
    disabled: disabled,
    pickerRef: pickerRef,
    value: durationHelpers_1.convertMillisecsToTimeString(value),
    onChange: onChange,
    label: label,
    mb: 0
  }, rest)));
};

exports.DurationPickerMS = DurationPickerMS;