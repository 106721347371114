"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Volume = exports.Area = exports.Length = void 0;
const UnitTypes_1 = require("../UnitTypes");
/**
 * Length
 */
exports.Length = new UnitTypes_1.NumberUnitDefinition({
    name: 'Length',
    label: 'Length',
    symbol: 'mm',
    precision: 0
});
/**
 * Area
 */
exports.Area = new UnitTypes_1.NumberUnitDefinition({
    name: 'Area',
    label: 'Area',
    symbol: 'mm²',
    precision: 0
});
/**
 * Volume
 */
exports.Volume = new UnitTypes_1.NumberUnitDefinition({
    name: 'Volume',
    label: 'Volume',
    symbol: 'mm³',
    precision: 0
});
