"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateAsString = exports.isValidDate = exports.updateTime = exports.formatDateValue = exports.formatTimeValue = exports.parsePartValueToDate = exports.parseDateString = exports.parseTimeString = exports.DatePickerFormats = exports.TimePickerFormats = exports.timeMaskYearsMonths = exports.timeMaskYearsMonthsDays = exports.timeMaskHoursMinSecs = exports.timeMaskHoursMins = exports.getRanges = exports.DATERANGES = exports.TIMERANGES = exports.TIME_VALUE_MAX = exports.DATE_DIVISIONS = exports.TIME_DIVISIONS = exports.DATE_SEPARATOR = exports.TIME_SEPARATOR = void 0;
exports.TIME_SEPARATOR = ':';
exports.DATE_SEPARATOR = '/';
exports.TIME_DIVISIONS = {
  day: 'day',
  hour: 'hour',
  minute: 'minute',
  second: 'second'
};
exports.DATE_DIVISIONS = {
  year: 'year',
  month: 'month',
  day: 'day'
};
exports.TIME_VALUE_MAX = {
  days: 5,
  hoursStandard: 12,
  hoursIntl: 23,
  minute: 59,
  second: 59
};
exports.TIMERANGES = {
  HH: {
    startRange: 0,
    endRange: 2
  },
  MM: {
    startRange: 3,
    endRange: 5
  },
  SS: {
    startRange: 6,
    endRange: 8
  }
};
exports.DATERANGES = {
  YYYY: {
    startRange: 0,
    endRange: 4
  },
  MM: {
    startRange: 5,
    endRange: 7
  },
  DD: {
    startRange: 8,
    endRange: 10
  }
};

const getRanges = format => {
  const splitTime = format.split(':');
  const splitDate = format.split('/');

  if (splitTime.length > 1) {
    return exports.TIMERANGES;
  }

  if (splitDate.length > 1) {
    return exports.DATERANGES;
  }
};

exports.getRanges = getRanges;

const timeMaskHoursMins = value => {
  const chars = value.split('');
  const hours = [/[0-2H]/, chars[0] === '2' ? /[0-3H]/ : /[0-9H]/];
  const minutes = [/[0-5M]/, /[0-9M]/];
  return hours.concat(exports.TIME_SEPARATOR).concat(minutes);
};

exports.timeMaskHoursMins = timeMaskHoursMins;

const timeMaskHoursMinSecs = value => {
  const chars = value.split('');
  const hours = [/[0-2H]/, chars[0] === '2' ? /[0-3H]/ : /[0-9H]/];
  const minutes = [/[0-5M]/, /[0-9M]/];
  const seconds = [/[0-5S]/, /[0-9S]/];
  return hours.concat(exports.TIME_SEPARATOR).concat(minutes).concat(exports.TIME_SEPARATOR).concat(seconds);
};

exports.timeMaskHoursMinSecs = timeMaskHoursMinSecs;

const timeMaskYearsMonthsDays = value => {
  const chars = value.split('');
  const years = [/[0-2Y]/, /[0-9Y]/, /[0-9Y]/, /[0-9Y]/];
  const months = [/[0-1M]/, chars[0] === '1' ? /[0-2M]/ : /[0-9M]/];
  const days = [/[0-3D]/, chars[0] === '3' ? /[0-1D]/ : /[0-9D]/];
  return years.concat(exports.DATE_SEPARATOR).concat(months).concat(exports.DATE_SEPARATOR).concat(days);
};

exports.timeMaskYearsMonthsDays = timeMaskYearsMonthsDays;

const timeMaskYearsMonths = value => {
  const chars = value.split('');
  const years = [/[0-2Y]/, /[0-9Y]/, /[0-9Y]/, /[0-9Y]/];
  const months = [/[0-1M]/, chars[0] === '1' ? /[0-2M]/ : /[0-9M]/];
  return years.concat(exports.DATE_SEPARATOR).concat(months).concat(exports.DATE_SEPARATOR);
};

exports.timeMaskYearsMonths = timeMaskYearsMonths;
exports.TimePickerFormats = {
  'HH:MM': {
    name: 'HH:MM',
    defaultValue: '00:00',
    mask: exports.timeMaskHoursMins
  },
  'HH:MM:SS': {
    name: 'HH:MM:SS',
    defaultValue: '00:00:00',
    mask: exports.timeMaskHoursMinSecs
  }
};
exports.DatePickerFormats = {
  'YYYY/MM/DD': {
    name: 'YYYY/MM/DD',
    defaultValue: '2000/01/01',
    mask: exports.timeMaskYearsMonthsDays
  },
  'YYYY/MM': {
    name: 'YYYY/MM',
    defaultValue: '2000/01',
    mask: exports.timeMaskYearsMonths
  }
};

const parseTimeString = value => {
  const [hours, minutes, seconds] = value.split(exports.TIME_SEPARATOR);
  return {
    hours: parseInt(hours, 10) || 0,
    minutes: parseInt(minutes, 10) || 0,
    seconds: parseInt(seconds, 10) || 0
  };
};

exports.parseTimeString = parseTimeString;

const parseDateString = value => {
  const [years, months, days] = value.split(exports.DATE_SEPARATOR);
  return {
    years: parseInt(years, 10) || 0,
    months: parseInt(months, 10) || 0,
    days: parseInt(days, 10) || 0
  };
};

exports.parseDateString = parseDateString;

const parsePartValueToDate = value => {
  const {
    years,
    months,
    days
  } = exports.parseDateString(value);
  return new Date(years, months, days);
};

exports.parsePartValueToDate = parsePartValueToDate; // Set default time for each segment (YYYY, MM, DD) if no value is available

const formatTimeValue = (value, format) => {
  const valueSplit = value ? value.split(exports.TIME_SEPARATOR) : [];
  const defaultTimeSplit = exports.TimePickerFormats[format].defaultValue.split(exports.TIME_SEPARATOR);
  const mappedValues = defaultTimeSplit.map((val, index) => (valueSplit[index] || defaultTimeSplit[index]).toString()).join(exports.TIME_SEPARATOR);
  return mappedValues;
};

exports.formatTimeValue = formatTimeValue; // Set default time for each segment (HH, MM, SS) if no value is available

const formatDateValue = (value, format) => {
  const valueSplit = value ? value.split(exports.DATE_SEPARATOR) : [];
  const defaultTimeSplit = exports.DatePickerFormats[format].defaultValue.split(exports.DATE_SEPARATOR);
  const mappedValues = defaultTimeSplit.map((val, index) => (valueSplit[index] || defaultTimeSplit[index]).toString()).join(exports.DATE_SEPARATOR);
  return mappedValues;
};

exports.formatDateValue = formatDateValue;

const updateTime = (division, value, time, format) => {
  const formattedTime = exports.formatTimeValue(time, format);
  const timeSplit = formattedTime.split(exports.TIME_SEPARATOR);

  switch (division) {
    case exports.TIME_DIVISIONS.hour:
      timeSplit[0] = value.toString();
      break;

    case exports.TIME_DIVISIONS.minute:
      timeSplit[1] = value.toString();
      break;

    case exports.TIME_DIVISIONS.second:
      timeSplit[2] = value.toString();
      break;

    default:
      break;
  } // send it along to parent component through onChange()


  return timeSplit.join(exports.TIME_SEPARATOR);
};

exports.updateTime = updateTime;

const isValidDate = d => d instanceof Date && !Number.isNaN(d);

exports.isValidDate = isValidDate;

const dateAsString = date => {
  if (date) {
    const mm = date.getMonth() + 1; // getMonth() is zero-based

    const dd = date.getDate();
    return `${date.getFullYear()}/${(mm > 9 ? '' : '0') + mm}/${(dd > 9 ? '' : '0') + dd}`;
  }

  return 'YYYY/MM/DD';
};

exports.dateAsString = dateAsString;