"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.waitForComponent = exports.mount = exports.QA = exports.firstOrUndefined = exports.atLeastOne = void 0;

const react_1 = require("@testing-library/react");
/**
 * Given an array, check that there is at least one item, otherwise throw an error with the given message.
 *
 * @param items The array of items to check.
 * @param message The message to show in the error.
 */


function atLeastOne(items, message = 'Expected at least one item') {
  if (items.length === 0) {
    throw new Error(message);
  }

  return items;
}

exports.atLeastOne = atLeastOne;
/**
 * Return the first item in an array of items if it exists, otherwise undefined.
 * @param items The array of items.
 */

function firstOrUndefined(items) {
  if (items.length === 0) {
    return;
  }

  return items[0];
}

exports.firstOrUndefined = firstOrUndefined;
/**
 * The QA component base class. All QA components should extend this.
 */

class QA {
  constructor(element, parent) {
    this.element = element;
    this.parent = parent;
  }
  /**
   * Reads the normalized text content of the html element.
   */


  get textContent() {
    const {
      textContent
    } = this.element;
    return textContent === null ? '' : textContent.trim();
  }
  /**
   * Creates a typescript friendly version of the QA base class for the given HTMLElement type.
   */


  static ofType() {
    return this;
  }
  /**
   * Returns true if this QA matches the given HTMLElement.
   */


  static matches(element, selector = this.selector) {
    return element.matches(selector);
  }
  /**
   * Get the path to the component.
   */


  get componentPath() {
    const {
      parent
    } = this;

    if (parent != null) {
      return parent.componentPath.concat(this.toString());
    }

    return [this.toString()];
  }
  /**
   * Creates a new QA with this as a parent.
   */


  instantiateComponent(QAClass, element) {
    return new QAClass(element, this);
  }
  /**
   * Creates an array of QAs with this as their parent.
   */


  instantiateComponents(QAClass, elements, selector = QAClass.selector) {
    return elements.filter(item => item instanceof HTMLElement && QAClass.matches(item, selector)).map(item => this.instantiateComponent(QAClass, item));
  }
  /**
   * Shortcut for `qa.element.querySelector()`.
   * @param selector The selector to query for.
   */


  querySelector(selector) {
    return this.element.querySelector(selector);
  }
  /**
   * Shortcut for `qa.element.querySelectorAll()` but returns a real array rather than a `NodeList`.
   * @param selector The selector to query for.
   */


  querySelectorAll(selector) {
    return Array.from(this.element.querySelectorAll(selector));
  }
  /**
   * Find all instances of the given QA within this DOM element.
   * @param QAClass The sub-class of QA to instantiate.
   * @param selector The optional custom selector for the QA.
   */


  queryAll(QAClass, selector = QAClass.selector) {
    const elements = this.querySelectorAll(selector);
    return this.instantiateComponents(QAClass, elements, selector);
  }
  /**
   * Find the first instance of the given QA within this DOM element.
   * @param QAClass The sub-class of QA to instantiate.
   * @param selector The optional custom selector for the QA.
   */


  query(QAClass, selector = QAClass.selector) {
    return firstOrUndefined(this.queryAll(QAClass, selector));
  }
  /**
   * Get all the instances of the given QA within this DOM element.
   * @param QAClass The sub-class of QA to instantiate.
   * @param selector The optional custom selector for the QA.
   * @throws if there is not at least one matching QA.
   */


  getAll(QAClass, selector = QAClass.selector) {
    return atLeastOne(this.queryAll(QAClass, selector), `Cannot find an element matching selector: ${selector}`);
  }
  /**
   * Get the first matching instance of the given QA within this DOM element.
   * @param QAClass The sub-class of QA to instantiate.
   * @param selector The optional custom selector for the QA.
   * @throws if there is not at least one matching QA.
   */


  get(QAClass, selector = QAClass.selector) {
    return this.getAll(QAClass, selector)[0];
  }
  /**
   *
   * @param QAClass
   * @param input
   */


  queryAllByAltText(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByAltText(this.element, input));
  }

  queryByAltText(QAClass, input) {
    return firstOrUndefined(this.queryAllByAltText(QAClass, input));
  }

  getAllByAltText(QAClass, input) {
    return atLeastOne(this.queryAllByAltText(QAClass, input), `Cannot find an element with alt text: ${String(input)}`);
  }

  getByAltText(QAClass, input) {
    return this.getAllByAltText(QAClass, input)[0];
  }

  queryAllByDisplayValue(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByDisplayValue(this.element, input));
  }

  queryByDisplayValue(QAClass, input) {
    return firstOrUndefined(this.queryAllByDisplayValue(QAClass, input));
  }

  getAllByDisplayValue(QAClass, input) {
    return atLeastOne(this.queryAllByDisplayValue(QAClass, input), `Cannot find an element with display value: ${String(input)}`);
  }

  getByDisplayValue(QAClass, input) {
    return this.getAllByDisplayValue(QAClass, input)[0];
  }

  queryAllByLabelText(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByLabelText(this.element, input));
  }

  queryByLabelText(QAClass, input) {
    return firstOrUndefined(this.queryAllByLabelText(QAClass, input));
  }

  getAllByLabelText(QAClass, input) {
    return atLeastOne(this.queryAllByLabelText(QAClass, input), `Cannot find an element with label text: ${String(input)}`);
  }

  getByLabelText(QAClass, input) {
    return this.getAllByLabelText(QAClass, input)[0];
  }

  queryAllByPlaceholderText(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByPlaceholderText(this.element, input));
  }

  queryByPlaceholderText(QAClass, input) {
    return firstOrUndefined(this.queryAllByPlaceholderText(QAClass, input));
  }

  getAllByPlaceholderText(QAClass, input) {
    return atLeastOne(this.queryAllByPlaceholderText(QAClass, input), `Cannot find an element with placeholder: ${String(input)}`);
  }

  getByPlaceholderText(QAClass, input) {
    return this.getAllByPlaceholderText(QAClass, input)[0];
  }

  queryAllByRole(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByRole(this.element, input));
  }

  queryByRole(QAClass, input) {
    return firstOrUndefined(this.queryAllByRole(QAClass, input));
  }

  getAllByRole(QAClass, input) {
    return atLeastOne(this.queryAllByRole(QAClass, input), `Cannot find an element with role: ${String(input)}`);
  }

  getByRole(QAClass, input) {
    return this.getAllByRole(QAClass, input)[0];
  }

  queryAllByTestId(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByTestId(this.element, input));
  }

  queryByTestId(QAClass, input) {
    return firstOrUndefined(this.queryAllByTestId(QAClass, input));
  }

  getAllByTestId(QAClass, input) {
    return atLeastOne(this.queryAllByTestId(QAClass, input), `Cannot find an element with test id: ${String(input)}`);
  }

  getByTestId(QAClass, input) {
    return this.getAllByTestId(QAClass, input)[0];
  }

  queryAllByText(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByText(this.element, input));
  }

  queryByText(QAClass, input) {
    return firstOrUndefined(this.queryAllByText(QAClass, input));
  }

  getAllByText(QAClass, input) {
    return atLeastOne(this.queryAllByText(QAClass, input), `Cannot find an element with text: ${String(input)}`);
  }

  getByText(QAClass, input) {
    return this.getAllByText(QAClass, input)[0];
  }

  queryAllByTitle(QAClass, input) {
    return this.instantiateComponents(QAClass, react_1.queryAllByTitle(this.element, input));
  }

  queryByTitle(QAClass, input) {
    return firstOrUndefined(this.queryAllByTitle(QAClass, input));
  }

  getAllByTitle(QAClass, input) {
    return atLeastOne(this.queryAllByTitle(QAClass, input), `Cannot find an element with title: ${String(input)}`);
  }

  getByTitle(QAClass, input) {
    return this.getAllByTitle(QAClass, input)[0];
  }

  getEventTarget(_eventName) {
    return this.element;
  }

  change(options = {}) {
    return this.fireEvent('change', options);
  }

  fireEvent(event, payload) {
    if (typeof event === 'string') {
      const options = payload || {};
      const eventTarget = this.getEventTarget(event);

      if (typeof react_1.fireEvent[event] === 'function') {
        return react_1.fireEvent[event](eventTarget, options);
      }

      return react_1.fireEvent(eventTarget, {
        type: event,
        ...options
      });
    }

    return react_1.fireEvent(this.getEventTarget(event.type), event);
  }

  toString() {
    return this.constructor.componentName;
  }

}

exports.QA = QA;

function mount(QAClass, container, selector = QAClass.selector) {
  if (QAClass.matches(container, selector)) {
    return new QAClass(container);
  }

  const elements = container.querySelectorAll(selector);

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (QAClass.matches(element, selector)) {
      return new QAClass(element);
    }
  }

  throw new Error(`Cannot find matching element for ${QAClass.componentName} with selector: ${selector}`);
}

exports.mount = mount;

function waitForComponent(QAClass, container, selector = QAClass.selector) {
  return react_1.waitForElement(() => mount(QAClass, container, selector));
}

exports.waitForComponent = waitForComponent;