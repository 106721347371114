import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { GlobalStyle, Theme, utils } from '@oqton/design-system';

import { config } from '../../config/config';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(config.stripe.key);

// Setup Google Analytics
ReactGA.initialize(config.googleAnalytics.key, {
  debug: process.env.NODE_ENV === 'development' || false
});
ReactGA.pageview(window.location.pathname + window.location.search);

export const App = ({ children, routes, NotFound }) => {
  const theme = utils.createTheme();

  return (
    <Theme theme={theme}>
      <GlobalStyle />
      <Elements stripe={stripePromise}>
        <Router>
          <Switch>
            {routes.map(({ path, Component }) => (
              <Route key={path} path={path} exact>
                <Component />
              </Route>
            ))}
            <Route>
              <NotFound />
            </Route>
          </Switch>
          {children}
        </Router>
      </Elements>
    </Theme>
  );
};
