"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTestableContext = exports.TestableContext = void 0;

const react_1 = require("react");

exports.TestableContext = react_1.createContext(undefined);
/***
 * @example const ctx = useTestableContext();
 * @example if (ctx && ctx.testing) {
 */

function useTestableContext() {
  return react_1.useContext(exports.TestableContext);
}

exports.useTestableContext = useTestableContext;