"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitDefinition = void 0;
const FormatToken_1 = require("./FormatToken");
const UnitTypes_1 = require("./UnitTypes");
/**
 * Base class for unit definitions.
 */
class UnitDefinition {
    constructor(config) {
        this.name = config.name;
        this.label = config.label;
        this.symbol = config.symbol;
        this.precision = config.precision;
    }
    /**
     * Format input as a string.
     * @param input The input to format.
     * @param precision The precision with which to format the input.
     * @param withSymbol Whether to include the symbol in the formatted output.
     */
    formatAsString(input, precision = this.precision, withSymbol = true) {
        if (this instanceof UnitTypes_1.DurationUnitDefinition && typeof input === 'number') {
            const inputAsMs = this.name === 'SECOND' ? input * 1000 : input;
            return this.formatDurationAsString(inputAsMs);
        }
        return FormatToken_1.joinFormatTokens(this.formatAsTokens(input, precision, withSymbol)) || '-';
    }
    /**
     * Format input as an array of tokens.
     * @param input The input to format.
     * @param precision The precision with which to format the input.
     * @param withSymbol Whether to include the symbol in the formatted output.
     */
    formatAsTokens(input, precision = this.precision, withSymbol = true) {
        const { symbol } = this;
        if (input == null) {
            return [];
        }
        const value = String(input);
        const tokens = [
            {
                type: 'value',
                value
            }
        ];
        if (withSymbol && symbol !== undefined && symbol.length > 0) {
            tokens.push({
                type: 'symbol',
                symbol
            });
        }
        return tokens;
    }
}
exports.UnitDefinition = UnitDefinition;
