"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useGlobalNav = void 0;

const react_1 = require("react");

const GlobalNavContext_1 = require("./GlobalNavContext");

function useGlobalNav() {
  const {
    scrollTop,
    open,
    setOpen,
    globalNavRef,
    setScrollTop
  } = react_1.useContext(GlobalNavContext_1.GlobalNavContext);
  return {
    scrollTop,
    open,
    setOpen,
    globalNavRef,
    setScrollTop
  };
}

exports.useGlobalNav = useGlobalNav;