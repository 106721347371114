"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Checkbox = void 0;

const react_1 = __importStar(require("react"));

const react_tippy_1 = require("react-tippy");

const inputs_1 = require("../../utils/inputs");

const foundations_1 = require("../../foundations");

const __1 = require("..");

const styleHelpers_1 = require("../../utils/styleHelpers");

const useTheme_1 = require("../../hooks/useTheme");

const index_1 = require("./index");

exports.Checkbox = react_1.default.memo(props => {
  const {
    checked,
    disabled,
    indeterminate,
    label,
    name = 'checkbox',
    tooltip,
    value,
    onChange = () => {},
    isHidden,
    isLocked,
    onClickHidden,
    onClickLock,
    testId
  } = props;
  const [active, setActive] = react_1.useState(false);
  const {
    colors
  } = useTheme_1.useTheme();
  const setIsActive = react_1.useCallback(isActive => {
    setActive(isActive);
  }, []);
  const toggleChecked = react_1.useCallback(e => {
    if (e) e.preventDefault(); // IMPORTANT, otherwise onClick can be triggered twice due to repaint

    onChange(!checked, {
      shiftKey: e ? e.shiftKey : false
    });
  }, [onChange, checked]);

  const getIcon = () => indeterminate ? react_1.default.createElement(__1.Icon, {
    name: "CheckboxIndeterminate",
    "data-test": "indeterminate"
  }) : react_1.default.createElement(__1.Icon, {
    name: "CheckboxCheck",
    "data-test": "check"
  });

  return react_1.default.createElement(foundations_1.Flex, {
    flexDirection: "row"
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxContainer, Object.assign({
    color: disabled ? colors.secondary : colors.primary,
    "data-testid": "Checkbox",
    checkboxBorder: disabled ? colors.checkboxColors.borderDisabled : colors.checkboxColors.border,
    checkboxBorderFocus: colors.checkboxColors.borderFocused,
    checkboxOutline: disabled ? colors.checkboxColors.outlineDisabled : colors.checkboxColors.outline,
    htmlFor: name,
    tabIndex: disabled ? undefined : 0,
    onClick: e => disabled ? null : toggleChecked(e),
    onKeyDown: e => {
      e.persist();

      if (inputs_1.isSpaceOrEnter(e.keyCode) && !disabled) {
        setIsActive(true);
      }
    },
    onKeyUp: e => {
      e.persist();

      if (inputs_1.isSpaceOrEnter(e.keyCode) && !disabled) {
        setIsActive(false);
        toggleChecked(e);
      }
    }
  }, styleHelpers_1.getSpaceProps(props)), react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center"
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckbox, {
    checked: checked,
    color: disabled ? colors.tertiary : colors.secondary,
    backgroundColor: disabled ? colors.checkboxColors.backgroundDisabled : colors.checkboxColors.background,
    checkboxOutlineActive: disabled || !active ? colors.checkboxColors.outlineDisabled : colors.checkboxColors.outlineActive,
    "data-testid": "CheckboxInner",
    "data-is-active": active,
    checkboxBorderFocus: colors.checkboxColors.borderFocused,
    tabIndex: disabled ? undefined : -1
  }, getIcon()), label && react_1.default.createElement(react_tippy_1.Tooltip, {
    title: tooltip,
    position: "top",
    arrow: true,
    disabled: !tooltip
  }, react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxLabel, {
    tabIndex: disabled ? undefined : -1
  }, label))), react_1.default.createElement("input", {
    checked: checked,
    disabled: disabled,
    name: name,
    type: "checkbox",
    value: value,
    onChange: e => onChange(e.currentTarget.checked, e),
    "data-testid": testId
  })), react_1.default.createElement(index_1.CheckboxStyles.StyledCheckboxIcons, null, react_1.default.createElement(__1.VisibilityControls, {
    disabled: disabled,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  })));
});