import {
  Box,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  CellText,
  CollapsableContainer,
  Flex,
  GuidanceBox,
  Image,
  InputNumber,
  Table,
} from '@oqton/design-system';
import { setIn } from '@oqton/utils-core';
import React from 'react';
import { List } from '../../../components/Style';

import { isPositiveFloat } from '../../../utils/validators';

export const DentalCAM = ({ data, setData }) => {
  return (
    <Card>
      <Flex width="100%">
        <Box mr="xl">
          <Image maxWidth="300" src="/images/dental_cam.png" />
        </Box>
        <Box ml="32px">
          <CardTitle>CAM for Dental</CardTitle>
          <CardSubtitle>Starting at EUR {data.monthly.camSmall.amount} per machine per month</CardSubtitle>
          <CardBody>
            <List
              items={[
                'Import, healing and part classification',
                'Automated build preparation for metal/polymer 3D printing',
                'High density nesting',
                'Automated CAM for Zirconia and metal machining',
                'Hybrid additive/subtractive manufacturing',
                'Integrated post processors',
              ]}
            />
            <Box my="l">
              <CollapsableContainer rowContent={<div>Supported Machines</div>}>
                <Table
                  rows={[
                    {
                      type: 'Additive',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint1000',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint2000',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Sisma',
                      name: 'MYSINT100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Sisma',
                      name: 'EVEMET200',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EOS',
                      name: 'M100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EOS',
                      name: 'M280 / M290',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'M100T',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'M150',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'EP-3500 / EP-A350 / EP-A450',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: 'EP-A650',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Shining3D',
                      name: '',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: '3DSystems',
                      name: 'ProX Flex 100',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: '3DSystems',
                      name: 'ProX DMP 200',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'Renishaw',
                      name: 'AM250 / AM400 / RenAM 500',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 125HL',
                      category: 'Medium',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 280HL',
                      category: 'Large',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Apollo / Apollo Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Aureus / Aureus Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Vida / Vida HD',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'Micro Plus HD / XL / Advantage',
                      category: 'Small',
                    },
                    {
                      type: 'Additive',
                      manufacturer: 'EnvisionTEC',
                      name: 'P3 / P4',
                      category: 'Medium',
                    },
                    {
                      type: 'Subtractive',
                      manufacturer: 'Imes Icore',
                      name: '350i',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint 1000',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Trumpf',
                      name: 'TruPrint 2000',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Sisma',
                      name: 'MYSINT100',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Sisma',
                      name: 'EVEMET200',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'EOS',
                      name: 'M 100',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'EOS',
                      name: 'M 270 / M 280 / M 290',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Laser Powder Bed',
                      manufacturer: 'EOS',
                      name: 'P 110',
                      category: 'Medium',
                    },
                    {
                      type: 'Polymer Laser Powder Bed',
                      manufacturer: 'EOS',
                      name: 'P 396 / P 500 / P 770 / P 810',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Laser Powder Bed',
                      manufacturer: 'Shining3D',
                      name: 'EP-M100T / EP-M150',
                      category: 'Medium',
                    },
                    {
                      type: 'Polymer Laser Powder Bed',
                      manufacturer: 'Shining3D',
                      name: 'EP-M250',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Shining3D',
                      name: 'EP-3500 / EP-A350 / EP-A450',
                      category: 'Medium',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Shining3D',
                      name: 'EP-A650',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: '3DSystems',
                      name: 'Phenix PXS / ProX DMP 100 / DMP Flex 100',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: '3DSystems',
                      name: 'Phenix PXM / ProX DMP 200',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Renishaw',
                      name: 'AM250 / AM400 / RenAM 500',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 125HL',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'SLM Solutions',
                      name: 'SLM 280HL / SLM 500HL',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Aconity3D',
                      name: 'AconityMINI',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Aconity3D',
                      name: 'AconityMIDI / AconityMIDI+ / AconityONE',
                      category: 'Large',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Concept Laser',
                      name: 'Mlab',
                      category: 'Medium',
                    },
                    {
                      type: 'Metal Laser Powder Bed',
                      manufacturer: 'Concept Laser',
                      name: 'M1 / M2',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'Apollo / Apollo Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'Aureus / Aureus Plus',
                      category: 'Small',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'Vida / Vida HD',
                      category: 'Small',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'Micro Plus HD / XL / Advantage',
                      category: 'Small',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'P3 / P4',
                      category: 'Medium',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'EnvisionTEC',
                      name: 'Envision One / D4K',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Structo',
                      name: 'Velox',
                      category: 'Medium',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Structo',
                      name: 'Dentaform',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Prodways',
                      name: 'ProMaker LD10',
                      category: 'Large',
                    },
                    {
                      type: 'Polymer Resin',
                      manufacturer: 'Prodways',
                      name: 'ProMaker LD20',
                      category: 'Large',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Imes Icore',
                      name: '245i',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Imes Icore',
                      name: '250i',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Imes Icore',
                      name: '350i',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Imes Icore',
                      name: '650i',
                      category: 'Large',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: 'DWX-52D',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: 'DWX-52DC',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: 'DWX-52DCi',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: '4',
                      category: 'Small',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: '4W',
                      category: 'Small',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: '50',
                      category: 'Medium',
                    },
                    {
                      type: 'CNC Milling',
                      manufacturer: 'Roland',
                      name: '51D',
                      category: 'Medium',
                    },
                  ]}
                  columns={[
                    {
                      flex: '1 0 100px',
                      Header: 'Type',
                      Cell: (row) => <CellText text={row.type} />,
                    },
                    {
                      flex: '1 0 100px',
                      Header: 'Manufacturer',
                      Cell: (row) => <CellText text={row.manufacturer} />,
                    },
                    {
                      flex: '2 0 200px',
                      Header: 'Model',
                      Cell: (row) => <CellText text={row.name} />,
                    },
                    {
                      flex: '2 0 200px',
                      Header: 'Category',
                      Cell: (row) => <CellText text={row.category} />,
                    },
                  ]}
                />
              </CollapsableContainer>
            </Box>
          </CardBody>
          <GuidanceBox mb="l">
            Select the number of machines of each category you want to be able to prepare files for.
          </GuidanceBox>
          <Box>
            <InputNumber
              type="number"
              label={`Small machines (EUR ${data.monthly.camSmall.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camSmall.quantity', v))}
              value={data.monthly.camSmall.quantity}
              validator={isPositiveFloat}
            />
          </Box>
          <Box>
            <InputNumber
              type="number"
              label={`Medium machines (EUR ${data.monthly.camMedium.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camMedium.quantity', v))}
              value={data.monthly.camMedium.quantity}
              validator={isPositiveFloat}
            />
          </Box>
          <Box>
            <InputNumber
              type="number"
              label={`Large machines (EUR ${data.monthly.camLarge.amount} per machine per month)`}
              onValidValueChange={(v) => setData(setIn(data, 'monthly.camLarge.quantity', v))}
              value={data.monthly.camLarge.quantity}
              validator={isPositiveFloat}
            />
          </Box>
        </Box>
      </Flex>
    </Card>
  );
};
