"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThemeOverridesConsumer = exports.useThemeOverridesContext = exports.ThemeOverridesContextProvider = exports.ThemeOverridesContext = void 0;

const react_1 = __importStar(require("react"));

exports.ThemeOverridesContext = react_1.default.createContext({});

const ThemeOverridesContextProvider = ({
  children
}) => {
  const [themeOverrides, setThemeOverrides] = react_1.useState({});
  const setNewThemeOverrides = react_1.useCallback(newOverrides => {
    setThemeOverrides({
      colors: newOverrides
    });
  }, []);
  const themeOverridesData = react_1.useMemo(() => ({
    themeOverrides,
    setNewThemeOverrides
  }), [themeOverrides, setNewThemeOverrides]);
  return react_1.default.createElement(exports.ThemeOverridesContext.Provider, {
    value: themeOverridesData
  }, children);
};

exports.ThemeOverridesContextProvider = ThemeOverridesContextProvider; // Export a Custom Hook

const useThemeOverridesContext = () => react_1.useContext(exports.ThemeOverridesContext);

exports.useThemeOverridesContext = useThemeOverridesContext;
exports.ThemeOverridesConsumer = exports.ThemeOverridesContext.Consumer;