"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InputText = void 0;

const react_1 = __importStar(require("react"));

const lodash_1 = require("lodash");

const index_1 = require("../index");

const useAutofocus_1 = require("../../hooks/useAutofocus");

const Input_1 = require("../Input");

const foundations_1 = require("../../foundations");

const components_1 = require("./components");

const mergeRefs_1 = require("./mergeRefs");

exports.InputText = react_1.default.memo(props => {
  const {
    type = 'text',
    children,
    inputFieldRef,
    disabled = false,
    error,
    warning,
    label = '',
    unit,
    onBlur,
    onFocus,
    onChange,
    onValidValueChange,
    tooltip,
    value,
    validator,
    textArea = false,
    style,
    placeholder,
    isHidden,
    isLocked,
    onClickHidden,
    onClickLock,
    testId,
    autoFocus,
    required,
    ...rest
  } = props;
  const [focused, setFocused] = react_1.useState(false);
  const [displayValue, setDisplayValue] = react_1.useState(value);
  const ref = useAutofocus_1.useAutofocus();
  const validationError = react_1.useMemo(() => {
    if (lodash_1.isFunction(validator)) {
      const validity = validator(String(focused ? displayValue : value));
      if (validity instanceof Input_1.ValidationError) return validity;
    }
  }, [validator, focused, displayValue, value]);
  let inputRef = react_1.useRef();
  inputRef = inputFieldRef || inputRef;
  const mergedInputRef = autoFocus ? mergeRefs_1.mergeRefs(inputRef, ref) : inputRef; // In case of no label, pass a non-breaking space into the label to keep error text aligned vertically

  const labelOrNBSP = label ? required ? label + ' *' : label : '';
  const focusInput = react_1.useCallback(() => {
    const {
      current
    } = inputRef;

    if (current) {
      current.focus();
    }
  }, [inputRef]);
  const handleFocus = react_1.useCallback(e => {
    setFocused(true);
    setDisplayValue(value);

    if (onFocus) {
      onFocus(e);
    }
  }, [onFocus, value]);
  const handleBlur = react_1.useCallback(e => {
    setFocused(false);

    if (onBlur) {
      onBlur(e);
    }
  }, [onBlur]);
  const handleChange = react_1.useMemo(() => !lodash_1.isFunction(validator) ? onChange : e => {
    setDisplayValue(e.currentTarget.value); // always display user input

    if (onValidValueChange) {
      const val = validator(e.currentTarget.value);
      if (!(val instanceof Input_1.ValidationError)) onValidValueChange(val !== undefined ? val : e.currentTarget.value);
    }

    if (onChange) onChange(e); // always call the original onChange, regardless of value validity
  }, [validator, onChange, onValidValueChange]);
  const handleWheel = react_1.useCallback(() => {
    const {
      current
    } = inputRef;
    if (current) current.blur();
  }, [inputRef]); // combine different possible error.

  let finalError = error;
  if (validationError) finalError = error ? `${error} and ${validationError.error}` : validationError.error;
  const inputProps = {
    inputRef,
    warning,
    error: finalError,
    disabled,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    onWheel: handleWheel,
    min: validator && validator.min,
    max: validator && validator.max,
    minLength: validator && validator.minLength,
    maxLength: validator && validator.maxLength,
    value: validator && focused ? displayValue : value,
    placeholder
  };
  const labelProps = {
    error: finalError,
    disabled,
    label: labelOrNBSP
  };

  const getLabel = () => {
    if (tooltip) {
      return react_1.default.createElement(index_1.InputTooltip, {
        title: tooltip,
        position: "top-start",
        arrow: true
      }, react_1.default.createElement(index_1.InputLabel, Object.assign({
        tooltip: !!tooltip,
        unit: !!unit
      }, labelProps)));
    }

    return react_1.default.createElement(index_1.InputLabel, Object.assign({
      unit: !!unit
    }, labelProps));
  };

  const InputComponent = textArea ? index_1.Textarea : index_1.BaseInput;
  return react_1.default.createElement(index_1.InputStyles.Wrapper, {
    style: style,
    textArea: textArea,
    finalError: finalError,
    warning: warning,
    "data-testid": "InputText"
  }, react_1.default.createElement(foundations_1.Flex, {
    alignItems: "center",
    height: "12px",
    onClick: focusInput
  }, getLabel()), react_1.default.createElement(InputComponent, Object.assign({
    autoComplete: "none",
    focused: focused,
    ref: mergedInputRef,
    type: type,
    "data-testid": testId,
    required: required
  }, inputProps, rest)), react_1.default.createElement(components_1.InputIcons, null, react_1.default.createElement(index_1.VisibilityControls, {
    disabled: disabled,
    isHidden: isHidden,
    isLocked: isLocked,
    onClickHidden: onClickHidden,
    onClickLock: onClickLock
  }), warning || finalError ? react_1.default.createElement(index_1.InputValidationIcon, {
    type: finalError ? 'error' : 'warning'
  }) : null), unit && displayValue && react_1.default.createElement(index_1.InputUnit, {
    unit: unit,
    value: displayValue,
    isDisabled: disabled
  }), finalError && react_1.default.createElement(index_1.InputValidation, {
    type: "error"
  }, finalError), !finalError && warning && react_1.default.createElement(index_1.InputValidation, {
    type: "warning"
  }, warning));
});