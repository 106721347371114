"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useDropdownMenuContext = void 0;

const react_1 = require("react");

const MenuInner_1 = require("./MenuInner");

const useDropdownMenuContext = () => react_1.useContext(MenuInner_1.MenuContext);

exports.useDropdownMenuContext = useDropdownMenuContext;