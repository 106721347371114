"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.typography = void 0;
const fonts = {
  sans: 'inherit'
};
const fontFamily = {
  english: 'centrano2, sans-serif',
  chinese: 'centrano2, "PingFangSC-Regular", sans-serif'
};
const fontSizes = {
  xxs: 10,
  xs: 11,
  s: 12,
  m: 13,
  l: 16,
  xl: 21,
  xxl: 24,
  xxxl: 32
};
const inputValue = 13.5;
const fontWeights = {
  thin: 300,
  normal: 'normal',
  medium: 500,
  bold: 'bold'
};
const letterSpacing = {
  none: 0,
  s: 0.02,
  m: 0.04,
  l: 0.08,
  xl: 0.1,
  xxl: 0.15
};
const transition = {
  formGroup: '0.1s linear'
};
exports.typography = {
  fonts,
  fontFamily,
  fontSizes,
  inputValue,
  fontWeights,
  transition,
  letterSpacing
};