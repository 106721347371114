import {
  Box,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Flex,
  GuidanceBox,
  Image,
  InputNumber,
} from '@oqton/design-system';
import { setIn } from '@oqton/utils-core';
import React, { useState } from 'react';
import { Layout, List } from '../../../components/Style';
import { Total } from '../components/Total';
import defaultJewelryData from '../data/jewelry.json';
import { JewelryCAM } from './JewelryCAM';

const VERTICALTYPE = 'jewelry';

import { isPositiveFloat } from '../../../utils/validators';

export const Jewelry = () => {
  const [data, setData] = useState(defaultJewelryData);

  return (
    <Layout width="540">
      <Box mb="l">
        <Card>
          <Flex>
            <Box mr="xl">
              <Image maxWidth="300" src="/images/factoryos.png" />
            </Box>
            <Box>
              <CardTitle>Oqton platform</CardTitle>
              <CardSubtitle>EUR {data.monthly.fos.amount} per user per month</CardSubtitle>
              <CardBody>
                <List
                  items={[
                    'Order tracking',
                    'Production execution',
                    'Automated scheduling',
                    'Full traceability',
                    'Production dashboards, reports and alerts',
                  ]}
                />
              </CardBody>
              <GuidanceBox mb="l">Oqton is the required core platform, select the number of users.</GuidanceBox>
              <Box>
                <InputNumber
                  type="number"
                  label="Number of users (EUR 20 per user per month)"
                  onValidValueChange={(v) => setData(setIn(data, 'monthly.fos.quantity', v))}
                  value={data.monthly.fos.quantity}
                  validator={isPositiveFloat}
                />
              </Box>
            </Box>
          </Flex>
        </Card>
      </Box>
      <Box mb="l">
        <JewelryCAM data={data} setData={setData} />
      </Box>
      <Total data={data} type={VERTICALTYPE} />
    </Layout>
  );
};
