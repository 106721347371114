"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropdownList = void 0;

const react_1 = __importStar(require("react"));

const dropdownHelpers_1 = require("../../utils/dropdownHelpers");

const ItemGroup_1 = require("./ItemGroup");

const index_1 = require("./index");

const DropdownList = ({
  align = 'right',
  listWidth,
  menuItems,
  onSelect,
  selected,
  menuHorizontalAlign,
  menuHorizontalOffset,
  menuVerticalAlign,
  menuVerticalOffset,
  ...rest
}) => {
  const ref = react_1.useRef();
  const [activeIndex, setActiveIndex] = react_1.useState(-1);
  const newMenuItems = dropdownHelpers_1.addIndex(menuItems);
  react_1.useEffect(() => {
    // add key up to close menu on exit
    const {
      current
    } = ref;
    current === null || current === void 0 ? void 0 : current.focus();

    const closeOnEsc = e => {
      if (e.keyCode === 27) {
        onSelect();
      }
    };

    if (typeof window !== `undefined`) {
      document.addEventListener('keyup', closeOnEsc);
    }

    return () => {
      if (typeof window !== `undefined`) {
        document.removeEventListener('keyup', closeOnEsc);
      }
    };
  }, [onSelect]);
  const handleFocus = react_1.useCallback((e, index) => {
    if (ref.current && ref.current.querySelectorAll) {
      if (ref.current.querySelectorAll('button')[index]) {
        ref.current.querySelectorAll('button')[index].focus();
        setActiveIndex(index);
      }
    }
  }, []);
  const handleOnKeyDown = react_1.useCallback(e => {
    var _a, _b, _c, _d;

    switch (e.key) {
      case 'Tab':
        {
          if (!e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();
            const index = dropdownHelpers_1.getNextSelectableItem(activeIndex, menuItems);
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll('button')[index].focus();
            setActiveIndex(index);
          } else {
            e.preventDefault();
            e.stopPropagation();
            const index = dropdownHelpers_1.getPreviousSelectableItem(activeIndex, menuItems);
            (_b = ref.current) === null || _b === void 0 ? void 0 : _b.querySelectorAll('button')[index].focus();
            setActiveIndex(index);
          }

          break;
        }

      case 'ArrowDown':
        {
          e.preventDefault();
          e.stopPropagation();
          const index = dropdownHelpers_1.getNextSelectableItem(activeIndex, menuItems);
          (_c = ref.current) === null || _c === void 0 ? void 0 : _c.querySelectorAll('button')[index].focus();
          setActiveIndex(index);
          break;
        }

      case 'ArrowUp':
        {
          e.preventDefault();
          e.stopPropagation();
          const index = dropdownHelpers_1.getPreviousSelectableItem(activeIndex, menuItems);
          (_d = ref.current) === null || _d === void 0 ? void 0 : _d.querySelectorAll('button')[index].focus();
          setActiveIndex(index);
          break;
        }

      default:
        break;
    }
  }, [activeIndex, menuItems]);
  return react_1.default.createElement(index_1.DropdownListStyles.PositionedList, Object.assign({
    tabIndex: 0,
    ref: ref,
    onKeyDown: handleOnKeyDown,
    role: "menu",
    align: align,
    listWidth: listWidth,
    "data-testid": "dropdown-menu",
    menuHorizontalAlign: menuHorizontalAlign,
    menuHorizontalOffset: menuHorizontalOffset,
    menuVerticalAlign: menuVerticalAlign,
    menuVerticalOffset: menuVerticalOffset
  }, rest), newMenuItems.map((item, i) => react_1.default.createElement(ItemGroup_1.ItemGroup, Object.assign({
    group: i,
    selected: selected,
    activeIndex: activeIndex,
    role: "menuitem",
    key: `menuitem-${i}`,
    onSelect: onSelect,
    listWidth: listWidth,
    handleFocus: handleFocus
  }, item))));
};

exports.DropdownList = DropdownList;