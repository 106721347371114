"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findInput = void 0;

const isValidInput = element => element.tagName === 'INPUT' && element.type === 'number';

const findInput = (element, property) => {
  let nextElement = element;

  do {
    nextElement = nextElement[property];
  } while (nextElement && !isValidInput(nextElement));

  return nextElement;
};

exports.findInput = findInput;