"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CNY = exports.EUR = void 0;
const UnitTypes_1 = require("../UnitTypes");
/**
 * EUR Currency
 */
exports.EUR = new UnitTypes_1.CurrencyUnitDefinition({
    name: 'EUR',
    label: 'Euro',
    symbol: '€'
});
/**
 * CNY Currency
 */
exports.CNY = new UnitTypes_1.CurrencyUnitDefinition({
    name: 'CNY',
    label: 'Yuan',
    symbol: '¥'
});
