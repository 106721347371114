"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Other = exports.Milliseconds = exports.DateTime = exports.WattSeconds = exports.Watts = exports.VoltAmpereReactive = exports.VoltAmperes = exports.Volts = exports.SiemensMeter = exports.Seconds = exports.RPM = exports.PH = exports.FractionalPercent = exports.Percent = exports.PascalSeconds = exports.MegaPascals = exports.Pascals = exports.Ohms = exports.NewtonMeters = exports.Newtons = exports.Millimeter3D = exports.MillimetersSecondSquared = exports.MillimetersSecond = exports.Millimeters = exports.MicroRadians = exports.LitersSecond = exports.Liters = exports.Kilograms = exports.Joules = exports.Hertz = exports.DegreesSecondSquared = exports.DegreesSecond = exports.Degrees = exports.Decibels = exports.Count = exports.Celsius = exports.Amperes = void 0;
const UnitDefinition_1 = require("../UnitDefinition");
const UnitTypes_1 = require("../UnitTypes");
/**
 * Amps
 */
exports.Amperes = new UnitTypes_1.NumberUnitDefinition({
    name: 'AMPERE',
    label: 'Amperes',
    symbol: 'A',
    precision: 2
});
/**
 * Degrees Celsius
 */
exports.Celsius = new UnitTypes_1.NumberUnitDefinition({
    name: 'CELSIUS',
    label: 'Celsius',
    symbol: '°C',
    precision: 2
});
/**
 * A counted event
 */
exports.Count = new UnitTypes_1.NumberUnitDefinition({
    name: 'COUNT',
    label: 'Count',
    symbol: '',
    precision: 2
});
/**
 * Sound Level
 */
exports.Decibels = new UnitTypes_1.NumberUnitDefinition({
    name: 'DECIBEL',
    label: 'Decibels',
    symbol: 'dB',
    precision: 2
});
/**
 * Angle in degrees
 */
exports.Degrees = new UnitTypes_1.NumberUnitDefinition({
    name: 'DEGREE',
    label: 'Degrees',
    symbol: '°',
    precision: 2
});
/**
 * Angular degrees per second
 */
exports.DegreesSecond = new UnitTypes_1.NumberUnitDefinition({
    name: 'DEGREE/SECOND',
    label: 'Degrees / Second',
    symbol: '°/s',
    precision: 2
});
/**
 * Angular acceleration in degrees per second squared
 */
exports.DegreesSecondSquared = new UnitTypes_1.NumberUnitDefinition({
    name: 'DEGREE/SECOND^2',
    label: 'Degrees / Second ^2',
    symbol: '°/s²',
    precision: 2
});
/**
 * Frequency measured in cycles per second
 */
exports.Hertz = new UnitTypes_1.NumberUnitDefinition({
    name: 'HERTZ',
    label: 'Hertz',
    symbol: 'Hz',
    precision: 2
});
/**
 * A measurement of energy
 */
exports.Joules = new UnitTypes_1.NumberUnitDefinition({
    name: 'JOULE',
    label: 'Joules',
    symbol: 'J',
    precision: 2
});
/**
 * Kilograms
 */
exports.Kilograms = new UnitTypes_1.NumberUnitDefinition({
    name: 'KILOGRAM',
    label: 'Kilograms',
    symbol: 'Kg',
    precision: 2
});
/**
 * Liters
 */
exports.Liters = new UnitTypes_1.NumberUnitDefinition({
    name: 'LITER',
    label: 'Liters',
    symbol: 'L',
    precision: 2
});
/**
 * Liters per second
 */
exports.LitersSecond = new UnitTypes_1.NumberUnitDefinition({
    name: 'LITER/SECOND',
    label: 'Liters / Second',
    symbol: 'L/s',
    precision: 2
});
/**
 * Measurement of Tilt
 */
exports.MicroRadians = new UnitTypes_1.NumberUnitDefinition({
    name: 'MICRO_RADIAN',
    label: 'Micro Radians',
    symbol: 'Âµrad',
    precision: 2
});
/**
 * Millimeters
 */
exports.Millimeters = new UnitTypes_1.NumberUnitDefinition({
    name: 'MILLIMETER',
    label: 'Millimeters',
    symbol: 'mm',
    precision: 2
});
/**
 * Millimeters per second
 */
exports.MillimetersSecond = new UnitTypes_1.NumberUnitDefinition({
    name: 'MILLIMETER/SECOND',
    label: 'Millimeters / Second',
    symbol: 'mm/s',
    precision: 2
});
/**
 * Acceleration in millimeters per second squared
 */
exports.MillimetersSecondSquared = new UnitTypes_1.NumberUnitDefinition({
    name: 'MILLIMETER/SECOND^2',
    label: 'Millimeters / Second ^2',
    symbol: 'mm/s²',
    precision: 2
});
/**
 * A point in space identified by X, Y, and Z positions and represented by a space-delimited set of numbers each expressed in millimeters.
 */
exports.Millimeter3D = new UnitTypes_1.PointUnitDefinition({
    name: 'MILLIMETER_3D',
    label: 'Millimeter 3D',
    symbol: 'mm-3d',
    precision: 2
});
/**
 * Force in Newtons
 */
exports.Newtons = new UnitTypes_1.NumberUnitDefinition({
    name: 'NEWTON',
    label: 'Newtons',
    symbol: 'N',
    precision: 2
});
/**
 * Torque, a unit for force times distance.
 */
exports.NewtonMeters = new UnitTypes_1.NumberUnitDefinition({
    name: 'NEWTON_METER',
    label: 'Newton Meters',
    symbol: 'N m',
    precision: 2
});
/**
 * Measure of Electrical Resistance
 */
exports.Ohms = new UnitTypes_1.NumberUnitDefinition({
    name: 'OHM',
    label: 'Ohms',
    symbol: 'Ω',
    precision: 2
});
/**
 * Pressure in Newtons per square meter
 */
exports.Pascals = new UnitTypes_1.NumberUnitDefinition({
    name: 'PASCAL',
    label: 'Pascals',
    symbol: 'Pa',
    precision: 2
});
/**
 * Pressure in Newtons per square millimeter
 */
exports.MegaPascals = new UnitTypes_1.NumberUnitDefinition({
    name: 'MEGAPASCAL',
    label: 'Megapascals',
    symbol: 'MPa',
    precision: 2
});
/**
 * Measurement of Viscosity
 */
exports.PascalSeconds = new UnitTypes_1.NumberUnitDefinition({
    name: 'PASCAL_SECOND',
    label: 'Pascal Seconds',
    symbol: 'Pa-s',
    precision: 2
});
/**
 * Percentage
 */
exports.Percent = new UnitTypes_1.PercentUnitDefinition({
    name: 'PERCENT',
    label: 'Percent',
    symbol: '%',
    precision: 2
});
/**
 * Fractional percentage
 */
exports.FractionalPercent = new UnitTypes_1.PercentUnitDefinition({
    name: 'FRACTIONAL_PERCENT',
    label: 'Percent',
    symbol: '%',
    precision: 2,
    scale: 100
});
/**
 * A measure of the acidity or alkalinity of a solution
 */
exports.PH = new UnitTypes_1.NumberUnitDefinition({
    name: 'PH',
    label: 'PH',
    symbol: 'PH',
    precision: 2
});
/**
 * Revolutions per minute
 */
exports.RPM = new UnitTypes_1.NumberUnitDefinition({
    name: 'REVOLUTION/MINUTE',
    label: 'RPM',
    symbol: 'RPM',
    precision: 2
});
/**
 * A measurement of time.
 */
exports.Seconds = new UnitTypes_1.DurationUnitDefinition({
    name: 'SECOND',
    label: 'Seconds',
    symbol: 's',
    scale: 1000
});
/**
 * A measurement of Electrical Conductivity
 */
exports.SiemensMeter = new UnitTypes_1.NumberUnitDefinition({
    name: 'SIEMENS/METER',
    label: 'Siemens / Meter',
    symbol: 'S/m',
    precision: 2
});
/**
 * Volts
 */
exports.Volts = new UnitTypes_1.NumberUnitDefinition({
    name: 'VOLT',
    label: 'Volts',
    symbol: 'V',
    precision: 2
});
/**
 * Volt-Ampere (VA)
 */
exports.VoltAmperes = new UnitTypes_1.NumberUnitDefinition({
    name: 'VOLT_AMPERE',
    label: 'Volt-Amperes',
    symbol: 'VA',
    precision: 2
});
/**
 * Volt-Ampere Reactive (VAR)
 */
exports.VoltAmpereReactive = new UnitTypes_1.NumberUnitDefinition({
    name: 'VOLT_AMPERE_REACTIVE',
    label: 'Volt-Ampere Reactive',
    symbol: 'VAR',
    precision: 2
});
/**
 * Watts
 */
exports.Watts = new UnitTypes_1.NumberUnitDefinition({
    name: 'WATT',
    label: 'Watts',
    symbol: 'W',
    precision: 2
});
/**
 * Measurement of electrical energy, equal to one Joule
 */
exports.WattSeconds = new UnitTypes_1.NumberUnitDefinition({
    name: 'WATT_SECOND',
    label: 'Watt Seconds',
    symbol: 'W-s',
    precision: 2
});
/**
 * An ISO formatted Date /Time
 */
exports.DateTime = new UnitTypes_1.DateTimeUnitDefinition({
    name: 'DATETIME',
    label: 'Date / Time',
    symbol: ''
});
/**
 * A measurement of time
 */
exports.Milliseconds = new UnitTypes_1.DurationUnitDefinition({
    name: 'MILLISECOND',
    label: 'Milliseconds',
    symbol: 'ms'
});
/**
 * Unknown Units
 */
exports.Other = new UnitDefinition_1.UnitDefinition({
    name: 'OTHER',
    label: 'Other',
    symbol: ''
});
